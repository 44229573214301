import Styles from "./RedirectPage.module.css";
import currencyExchange from "../../../Assets/Images/goldefi/currency-exchange.svg";
import fiatIcon from "../../../Assets/Images/goldefi/fiatPayment.svg";
import cashIcon from "../../../Assets/Images/goldefi/cashPayment.svg";
import ButtonStyles from "../../../Components/Common/Button.module.css";
import { Link } from "react-router-dom";
import { BiDollar } from "react-icons/bi";

const RedirectPage = ({
  mode,
  isSuccess,
  orderDetails,
  successfulTransaction,
}: any) => {
  return (
    <div className={Styles.container}>
      <div className="d-flex justify-content-center">
        <img
          src={
            mode === "crypto"
              ? currencyExchange
              : mode === "fiat"
              ? fiatIcon
              : cashIcon
          }
          alt="transaction"
        />
      </div>
      <div className={Styles.textContainer}>
        <div
          className={
            (isSuccess ? Styles.isSuccess : "") + " " + Styles.txStatus
          }>
          Transaction pending
        </div>
        {!isSuccess ? (
          <div className={Styles.orderInfo}>
            Order ID - {orderDetails.orderId}
          </div>
        ) : (
          <div className={Styles.successText}>
            <div className={Styles.text2}>Congratulations!!!</div>
            The transaction is complete, the tokens are minted and transferred
            to your GolDeFi account.
          </div>
        )}
        {!isSuccess ? (
          <div className={Styles.text}>
            {mode === "cash" ? (
              <>
                Please visit the nearest distributor to finalize your purchase.
                This involves physically verifying your KYC and completing the
                selected transaction.
              </>
            ) : (
              <>
                A request has been initiated to transfer funds from your
                Metamask wallet. Kindly log in to your Metamask account and
                authorize the request.
                <br />
                <br />
                Once the transaction is completed, the tokens will be minted and
                transferred to your GolDeFi account.
              </>
            )}
          </div>
        ) : (
          <div className={Styles.successContainer}>
            <div className={Styles.successText2}>
              Order ID - {orderDetails.orderId}
            </div>
            <div className={Styles.successCardContainer}>
              <div className={Styles.successCard}>
                <div className={Styles.txtHead}>Token Purchased</div>
                <div className={Styles.txtSub}>
                  {successfulTransaction?.token_purchased}
                </div>
              </div>
              <div className={Styles.successCard}>
                <div className={Styles.txtHead}>Purchase Amount</div>
                <div className={`d-flex   ${Styles.txtSub}`}>
                  <BiDollar /> {successfulTransaction?.purchase_amount}
                </div>
              </div>
              <div className={Styles.successCard}>
                <div className={Styles.txtHead}>Weight of Gold</div>
                <div className={Styles.txtSub}>
                  {successfulTransaction?.weight_of_gold}{" "}
                  <span className={Styles.unit}>Troy oz</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={Styles.textContainer}>
        <div className={Styles.btnContainer}>
          <button
            type="submit"
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} ${Styles.btn}`}
            // onClick={() => setRedirectScreen("CryptoScreen")}
          >
            {mode === "crypto"
              ? "Go to Metamask"
              : mode === "fiat"
              ? "Go to My Tokens"
              : "View your nearest distributor"}
          </button>
        </div>
        <div>
          <Link to="/dashboard" className={Styles.link}>
            Go to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RedirectPage;
