import React from "react";
import RedeemToken from "../Components/RedeemTokenComponent";
import UserLayout from "../Layouts/UserLayout/UserLayout";

const RedeemTokens = () => {
  return (
    <UserLayout title="Redeem Token" showPaddding={false}>
      <RedeemToken />
    </UserLayout>
  );
};

export default RedeemTokens;
