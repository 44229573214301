import React, { Fragment, useState } from "react";
import Styles from "./faq.module.css";

import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

import { Col, Row } from "react-bootstrap";
const FaqComponent = ({ data = [], short = false }) => {
  const [scrollPosition, setScrollPosition] = useState(400);

  const scrollCards = (direction:'left'|'right') => {
    const container = document.querySelector(".faqMainConatiner");
    const scrollAmount = 600; // Adjust as needed
    if (!container) {
      return;
    }
    if (direction === "left") {
      container.scrollLeft -= scrollAmount;
      setScrollPosition(container.scrollLeft);
    } else if (direction === "right") {
      container.scrollLeft += scrollAmount;
      setScrollPosition(container.scrollLeft);
    }
  };
  const tdata = new Array(10).fill({
    heading: "Etiam mi mauris, rhoncus non turpis ut, volutpat consequat",
    text: "Vivamus vitae dictum nibh, sit amet rutrum metus. Donec volutpat gravida dui, eget accumsan ",
  });

  const FaqCard = (data: { index: number; heading: string; text: string }) => {
    return (
      <Col md={3}>
        <div className={Styles.faqCard}>
          <div className={Styles.faqBadge}>{data.index}</div>
          <div className={Styles.faqCardHeading+ ' px-3 mt-2'}>{data.heading}</div>
          <div className={Styles.faqCardBody + ' px-3 mt-1'}>{data.text}</div>
        </div>
      </Col>
    );
  };
  
  const CrouserBtn = (position:any ) => {
    return (
        <div className={"d-flex " +' justify-content-'+position?.position}>
          <div className={Styles.leftBtnContainer +' justify-content-'+position?.position}>
            <button type="button" className={Styles.leftBtn} onClick={()=>{scrollCards('left')}}>
              <BsArrowLeft />
            </button>
          </div>
         
          <div className={Styles.rightBtnContainer}>
            <button type="button" className={Styles.rightBtn} onClick={()=>{scrollCards('right')}}>
              <BsArrowRight />
            </button>
          </div>
        </div>
    );
  };
  return (
    <Fragment>
      <Row>
        <div className={Styles.faqHeading}>FAQ's</div>
      </Row>
      <Row>
        <Col md={3} sm={12} className={Styles.faqSubContianerTitle}>
          Morbi vehicula id enim at rhoncus.
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={12} className={Styles.faqSubContianer}>
          Vivamus vitae dictum nibh, sit amet rutrum metus. Donec volutpat
          gravida dui, eget accumsan
        </Col>
        <Col md={7} sm={0} className={Styles.crouserBtns}>
          <CrouserBtn position={'end'} />
        </Col>
      </Row>
      <Row sm={12} md={12}>
        <div className={Styles.faqContainer + ' faqMainConatiner'}>
          {tdata.map((d, idx: number) => (
            <FaqCard
              index={idx}
              key={`card-faq+${idx}`}
              heading={d.heading}
              text={d.text}
            />
          ))}
        </div>
      </Row>
      <Row>
      <Col sm={12} className={Styles.crouserBtnsMobile}>
          <CrouserBtn position={'center'} />
        </Col>
      </Row>
    </Fragment>
  );
};
export default FaqComponent;
