import { useEffect } from "react";
import Styles from "./order.module.css";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { IoMdArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
interface props {
  data: any[];
}
interface OrderDataType {
  orderData: any;
  idx: number;
}
const Order = ({ orderData, idx }: OrderDataType) => {
  const GetOrderStatus = (status: string) => {
    if (status === "Completed") {
      return (
        <span className="px-3 py-2 badge bg-success  rounded text-uppercase">
          {status}
        </span>
      );
    }
    if (status === "In Process") {
      return (
        <span
          className="px-3 py-2 badge rounded text-uppercase"
          style={{ backgroundColor: "#8e810c" }}>
          {status}
        </span>
      );
    }
    return (
      <span
        className="px-3 py-2 badge rounded text-uppercase"
        style={{ background: "#902c0a" }}>
        {status}
      </span>
    );
  };
  return (
    <div
      className={idx % 2 ? Styles.orderBoxDark : Styles.orderBoxLight}
      key={"order-" + orderData.order_id}>
      <div className="row">
        <div className="col-md-2 col-sm-12 col-xs-12">
          <div className={Styles.orderItem + " row"}>
            <div
              className={
                Styles.orderBoxTitle + " col-md-12 col-sm-6 col-6 text-start "
              }>
              Order Date
            </div>
            <div
              className={
                Styles.orderBoxValue + " col-md-12 col-sm-6 col-6 text-start "
              }>
              {moment(orderData.date_order).format("ll")}
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-12">
          <div className={Styles.orderItem + " row"}>
            <div
              className={
                Styles.orderBoxTitle + " col-md-12 col-sm-6 col-6 text-start"
              }>
              {" "}
              Reference #
            </div>
            <div
              className={
                Styles.orderBoxValue + " col-md-12 col-sm-6  col-6 text-start"
              }>
              {orderData.order_name}
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-12">
          <div className={Styles.orderItem + " row"}>
            <div
              className={
                Styles.orderBoxTitle + " col-md-12 col-sm-6 col-6 text-start"
              }>
              Qty
            </div>
            <div
              className={
                Styles.orderBoxValue + " col-md-12 col-sm-6  col-6 text-start"
              }>
              {orderData.qty}
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-12">
          <div className={Styles.orderItem + " row"}>
            <div
              className={
                Styles.orderBoxTitle + " col-md-12 col-sm-6 col-6 text-start"
              }>
              {" "}
              Amount Paid
            </div>
            <div
              className={
                Styles.orderBoxValue + " col-md-12 col-sm-6 col-6 text-start"
              }>
              <CurrencyFormat
                value={orderData.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-12">
          <div className={Styles.orderItem + " row"}>
            <div
              className={
                Styles.orderBoxTitle + " col-md-12 col-sm-6 col-6 text-start"
              }>
              {" "}
              Payment Type
            </div>
            <div
              className={
                Styles.orderBoxValue + " col-md-12 col-sm-6 col-6 text-start"
              }>
              {orderData.payment_mode ? orderData.payment_mode : "UNSURE"}
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-12">
          <div className={Styles.orderItem + " row"}>
            <div
              className={
                Styles.orderBoxTitle + " col-md-12 col-sm-6 col-6 text-start"
              }>
              {" "}
              Order Status
            </div>
            <div
              className={
                Styles.orderBoxValue + " col-md-12 col-sm-6  col-6 text-start"
              }>
              {orderData.state ? GetOrderStatus(orderData.state) : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderComponent = ({ data }: props) => {
  useEffect(() => {}, []);
  const navigate = useNavigate();
  return (
    <div className={Styles.orderContainer + " "}>
      <div className={Styles.orderHeadingContainer}>
        <h5 className="text-white ">Recent Orders</h5>
        <div className={Styles.more} onClick={() => navigate("/my-orders")}>
          More <IoMdArrowRoundForward />
        </div>
      </div>
      <hr></hr>
      <div className="col-12 mb-3">
        {data && data.length > 0 ? (
          data.map((d: any, index: number) => {
            if (index < 5) {
              return (
                <Order
                  orderData={d}
                  idx={index}
                  key={d.order_id + " - order"}
                />
              );
            }
          })
        ) : (
          <div className={Styles.orderBox}>
            <div className="row gy-3 py-1">
              <div className="d-flex align-items-center justify-content-center">
                No Order Found !!!
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default OrderComponent;
