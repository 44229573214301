import React from "react";
import SearchDistributerComponent from "../Components/SearchDistributerComponent";
import UserLayout from "../Layouts/UserLayout/UserLayout";
const searchDistrubuter = () => {
  return (
    <UserLayout showPaddding={false} title="Search Distributer">
      <SearchDistributerComponent />
    </UserLayout>
  );
};

export default searchDistrubuter;
