import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div className="Loader">
        <Spinner animation="border" variant="warning" />
    </div>
  );
};
export default Loader;
