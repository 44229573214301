import React from "react";
import Styles from "./SupportHeader.module.css";
import SupportHeaderImg from "../../../Assets/Images/why-graphite-banner-1.png";
const SupportHeader = () => {
  return (
    <div className={Styles.bannerContainer}>
      <div className="d-flex justify-space-between">
        <div className="col-8">
          <div className="mt-5 px-4 row">
            <div className={Styles.header1 + ' col-12' }>Support Centre</div>
            <p className={Styles.header2 + ' text-left pt-2'}>TBC</p>
          </div>
        </div>
        <div className="col-4">
          <div>
            <img
              className={Styles.bannerImage }
              src={SupportHeaderImg}
              alt="banner_image"
            />
          </div>
        </div>


      </div>

    </div>
  );
};

export default SupportHeader;
