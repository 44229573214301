import React from "react";
import MetaTransactionComponent from "../Components/MetaTransactionComponent";
import UserLayout from "../Layouts/UserLayout/UserLayout";
const Metatransaction = () => {
  return (
    <UserLayout showPaddding={false} title="Meta Transaction">
      <MetaTransactionComponent />
    </UserLayout>
  );
};

export default Metatransaction;
