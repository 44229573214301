import React, { useEffect, useState } from "react";
import UserHeader from "../Components/Common/UserHeader";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import Navigation from "../Components/Common/Navigation";
import ErrorBoundary from "../Components/Common/ErrorBoundary";
import Styles from "./Layout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setScreenSize } from "../redux/actions";

const Layout = ({
  children,
  title = "Goldefi",
  bodyBackgroundColor = "#00112B",
  showFooter = true,
}: any) => {
  const { screenSize } = useSelector((state: any) => state.layoutReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = title;
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [title]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 426 && window.innerWidth < 769) {
        dispatch(setScreenSize(768));
      } else if (window.innerWidth < 426 || window.innerWidth == 426) {
        dispatch(setScreenSize(425));
      } else {
        dispatch(setScreenSize(1024));
      }
    };
    if (screenSize == 0) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={Styles.LayoutContainer}>
      <UserHeader title={title} />
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <div
          className={Styles.PageBody}
          style={{ backgroundColor: bodyBackgroundColor }}>
          {children}
        </div>
      </ErrorBoundary>
      {showFooter && <Footer />}
    </div>
  );
};
export default Layout;
