// @ts-nocheck

import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOTP } from "../../redux/actions";
import { validateQrCodeAPI } from "../../service/api";
import Styles from "./submitOtp.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";
import notify from "../../utils/notify";
import AuthLayout from "../../Components/Common/LayoutAuthPages";
import {
  getAWSSession,
  getAWSToken,
  getUserEmail,
  getUserId,
} from "../../utils/common";
import useAlert from "../../utils/hooks/alert";
import loginSubmit from "../../utils/login-submit";

const SubmitOtpComponent = () => {
  // for screen navigation purpose
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const [otpError, setOtpError] = useState(false);
  const [otp, setOtp] = useState("");
  const [verifyOtpStatus, setVerifyOtpStatus] = useState("");
  const [resendCodeApiMessage, setResendCodeApiMessage] = useState("");
  const { username } = useSelector((state) => state.userReducer);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      aws_sso_token: getAWSToken(),
      qr_code: otp,
      aws_sso_session: getAWSSession(),
      user_name: getUserEmail(),
      multi_tsp_user_id: getUserId(),
    };
    const res = await validateQrCodeAPI(data);
    if (res.status) {
      const result = loginSubmit(res, dispatch, showAlert, "submit");
      navigate(result);
    } else {
      showAlert("ERROR", "Error", res.message);  
    }
  };
  return (
    <AuthLayout>
      <div className={Styles.loginContainer}>
        <div className={Styles.thirdLogin}>
          <div className={Styles.heading}>
            Verify OTP
          </div>
          <div className={Styles.subHeading}>
            Enter verification code
          </div>
        </div>
        <div className={Styles.loginform}>
          <form className={Styles.Form} onSubmit={handleSubmit}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span style={{ width: "8px" }}></span>}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus={true}
              inputStyle={Styles.InputComponent}
              skipDefaultStyles={true}
              inputType="number"
              containerStyle={Styles.OTPContainer}
            />
            {otpError && (
              <p className="text-danger">
                <small>Not a valid Code</small>
              </p>
            )}

            <div className={Styles.loginBtn}>
              <button
                type="submit"
                className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}
              >
                <span className={Styles.FormButtonSpan}>Validate OTP</span>
              </button>
            </div>
            
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SubmitOtpComponent;
