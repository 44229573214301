// @ts-nocheck

import React, { useState } from "react";
import Styles from "./checkEmail.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";
import AuthLayout from "../../Components/Common/LayoutAuthPages";
import emailIcon from "../../Assets/Images/goldefi/checkEmail.svg";

const CheckEmailComponent = () => {
  const [isSubmit, setIsSubmit] = useState<Boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
  };

  return (
    <AuthLayout>
      <div className={Styles.loginContainer}>
        <div className={Styles.image}>
          <img src={emailIcon} alt="email" />
        </div>
        <div className={Styles.thirdLogin}>
          <div className={Styles.heading}>Check your Email</div>
          <div className={Styles.subHeading}>Please check the email address info@goldefi.com for instructions to reset your password.</div>
        </div>
        <div className={Styles.loginform}>
          <form className={Styles.Form} onSubmit={handleSubmit}>
            <div className={Styles.loginBtn}>
              <button
                type="submit"
                disabled={isSubmit}
                className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}
              >
                <span className={Styles.FormButtonSpan}>Resend Email</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default CheckEmailComponent;
