import React from "react";
import Layout from "../Layouts/Layout";
import DistributorLoginComponent from "../Components/DistributorLoginComponent";

const DistributorLogin = () => {
  return (
    // <Layout title="Login">
      <DistributorLoginComponent />
    // </Layout>
  );
};

export default DistributorLogin;
