// @ts-nocheck
import { useState } from "react";
import Styles from "./searchOrders.module.css";
import { ethers } from "ethers";
import ButtonStyle from "../Common/Button.module.css";
import {
  createMetaTransactionAPI,
  relayerBulkTransferTransaction,
  relayerSubsidizeTransaction,
  updateDirectTransactionHashAPI,
} from "../../service/api";
import { metaTransactionValidation } from "../../utils/common/commonValidation";
import SearchIcon from "../../Assets/Images/goldefi/searchIcon.svg";
import SearchIcon2 from "../../Assets/Images/goldefi/searchLoading.svg";
import LoaderComponent from "../Common/LoaderComponent";
import notify from "../../utils/notify";
import InputField from "../Common/InputField";
import { useSelector } from "react-redux";
import metamask from "../../utils/metamask";
import ConfirmModelComponent from "./ConfirmModelComponent";
import { createRedemptionSign, directTranferToken, getChains } from "../../service/thirdweb_service";

const MetaTransactionComponent = () => {
  const [metaTransaction, setMetaTransaction] = useState({
    address: "",
    amount: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [distributorList, setDistributorList] = useState([]);
  const [data, setData] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const { userDetails } = useSelector((state: any) => state.userReducer);
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setMetaTransaction({
      ...metaTransaction,
      [name]: value,
    });
  };
  const getMetaTransaction = async () => {
    const check = metaTransactionValidation(
      metaTransaction,
      userDetails.wallet_address
    );
    if (check.validation) {
      notify.error(check.message);
      return;
    }
    if (!ethers.isAddress(metaTransaction.address)) {
      notify.error("Please enter a valid address");
      return;
    }
    const body = {
      to_address: metaTransaction.address,
      amount: metaTransaction.amount,
    };
    setShowLoader(true);
    setDisableButton(true);
    const res: any = await createMetaTransactionAPI(body);
    setShowLoader(false);

    if (res.status) {
      if (res.payment_type === "gdi_tokens") {
        setShowModal(true);
        setData(res);
      } else {
        handlePayment(res, true);
      }
      // const hash = await metamask.signMessage(JSON.stringify(res.txn));
      // console.log("HASH", hash);
      // setDistributorList(res.data);
      notify.success(res.message);
    } else {
      notify.error(res.message);
    }
  };
  const handlePayment = async (info, flag) => {
    const res = flag ? info : data;
    if (res.payment_type === "native") {
      setShowLoader(true);
      const response = await directTranferToken(
        userDetails.wallet_address,
        parseFloat(metaTransaction.amount),
        res.contract_address,
        res.platformRecipient,
      );

      if (response.result) {
        saveTransactionHash(response.result, res);
      }
      setDisableButton(false);
      setShowLoader(false);
    } else {
      const { request, ...rest } = res.toSign.payload;
      const body = { ...rest, message: request };
      setShowLoader(true);
      const resp = await createRedemptionSign(
        userDetails.wallet_address,
        body,
        getChains('polygon')
      );
      if (resp.result) {
        if (res.payment_type === "subsidized") {
          const info = {
            to_address: metaTransaction.address,
            value: parseFloat(metaTransaction.amount),
            user_wallet: res.user_wallet,
            payment_type: res.payment_type,
            signature: resp.result,
            deadline: body.message.deadline,
          };
          const response: any = await relayerSubsidizeTransaction(info);
          if (response.status === 1) {
            saveTransactionHash(response.hash, res);
          }
        } else if (res.payment_type === "gdi_tokens") {
          const info = {
            to_addresses: [metaTransaction.address, res.platformRecipient],
            values: [parseFloat(metaTransaction.amount), res.fee],
            user_wallet: res.user_wallet,
            to: res.to,
            signature: resp.result,
            deadline: body.message.deadline,
          };
          const response: any = await relayerBulkTransferTransaction(info);
          if (response.status === 1) {
            saveTransactionHash(response.hash, res);
          }
        }
      }
      setShowLoader(false);
      setDisableButton(false);
    }
  };
  const saveTransactionHash = async (hash: string, res) => {
    const body = {
      active: res.payment_type,
      transaction_hash: hash,
    };
    setDisableButton(true);

    const resp: any = await updateDirectTransactionHashAPI(body);
    if (resp.status) {
      setMetaTransaction({
        address: "",
        amount: "",
      });
      notify.success(resp.message);
    } else {
      notify.error(resp.message);
    }
    setDisableButton(false);

    setShowModal(false);
  };
  const DesktopListTable = () => {
    return (
      <div className="row mt-4 w-100 overflow-hidden">
        <div className="col-md-12 mx-3 px-3 mb-3">
          <div className="d-flex justify-content-between">
            <div className="tableHeading col px-1 fs-6">Name</div>
            <div className="tableHeading col px-1 fs-6">Phone</div>
            <div className="tableHeading col px-1 fs-6">Email</div>
            <div className="tableHeading col px-1 fs-6">Street</div>
            <div className="tableHeading col px-1 fs-6">City</div>
            <div className="tableHeading col px-1 fs-6">State</div>
            <div className="tableHeading col  fs-6">Country</div>
          </div>
        </div>
        {distributorList &&
          distributorList.length > 0 &&
          distributorList.map((d: any, idx: number) => {
            return (
              <div
                key={`order-+${idx}`}
                className={
                  idx % 2
                    ? "col-md-12 mx-3 tableRowDark py-4 px-3 mt-1"
                    : "col-md-12 mx-3 tableRowLight py-4 px-3 mt-1"
                }>
                <div className="d-flex justify-content-between">
                  <div className=" tableText col px-1 fs-6">
                    {d.name || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.phone || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.email || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.street + " " + d.street || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.city || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.state || "N/A"}
                  </div>
                  <div className="tableText col px-1 fs-6">
                    {d.country || "N/A"}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };
  const MobileOrderCard = () => {
    return (
      <>
        {distributorList && distributorList.length > 0 ? (
          distributorList.map((d: any, idx: number) => {
            return (
              <div
                key={`order-card-+${idx}`}
                className={
                  idx % 2
                    ? "row orderCardDark py-3 px-2 mb-4"
                    : "row orderCardLight py-3 px-2 mb-4"
                }>
                <div className="tableHeading col px-1 fs-6"></div>

                <div className="row mt-2 py-2">
                  {/* <hr className="px-auto mt-1 text-white" /> */}
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Name</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.name || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Phone</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.phone || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Email</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.email || "N/A"} </div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Street</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">
                        {d.street + " " + d.street || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">City</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.city || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">State</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.state || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">State</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.state || "N/A"}</div>
                    </div>
                  </div>
                  <div className="row mt-2 py-2">
                    <div className="col-md-5 col-5 tableHeading text-start ">
                      <div className="col-md-12">Country</div>
                    </div>
                    <div className="col-md-7 col-7 tableText text-start">
                      <div className="col-md-12">{d.countey || "N/A"}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div key="order-card-1" className="row orderCardDark py-3 px-2 mb-4">
            <h3 className="text-center text-white"> No Order Present</h3>
          </div>
        )}
      </>
    );
  };
  return (
    <div className={Styles.mainDiv}>
      <div className={Styles.searchBar}>
        <div className={Styles.srchtxt}>
          <img className={Styles.srchImg} src={SearchIcon} alt="Search" />
          <h6 className={Styles.txt}>
            Send your GDI Tokens to another account
          </h6>
        </div>
        <div className={Styles.inputToken}>
          <InputField
            label="Address"
            value={metaTransaction.address}
            type="text"
            placeholder="Address"
            onChange={changeHandler}
            // height="50px"
            name="address"
            maxLength={50}
            dark={true}
            autoComplete="false"
          />
        </div>
        <div className={Styles.inputToken}>
          <InputField
            label="Amount"
            value={metaTransaction.amount}
            type="text"
            placeholder="Amount"
            onChange={(e) => {
              const inputValue = e.target.value;

              if (/^\d*$/.test(inputValue)) {
                const intValue = parseInt(inputValue, 10);
                if (
                  (isNaN(intValue) || intValue >= -1) &&
                  JSON.stringify(intValue).length < 10
                ) {
                  changeHandler(e);
                }
              }
            }}
            // height="50px"
            name="amount"
            maxLength={40}
            dark={true}
            autoComplete="false"
          />
        </div>

        <button
          onClick={getMetaTransaction}
          disabled={disableButton}
          className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.btn} me-3 px-2`}>
          Send
        </button>
      </div>

      {distributorList.length === 0 ? (
        <div className={Styles.search}>
          <img className={Styles.srchImg2} src={SearchIcon2} alt="Search" />
        </div>
      ) : (
        <>
          <div className="d-none d-md-block d-xl-block mb-4">
            <DesktopListTable />
          </div>
          <div className="d-block d-xl-none	 d-md-none mb-4">
            <MobileOrderCard />
          </div>
        </>
      )}

      {showLoader && (
        <LoaderComponent message={"Performing your transaction!"} />
      )}
      {showModal && (
        <ConfirmModelComponent
          showModal={showModal}
          setShowModal={setShowModal}
          setShowLoader={setShowLoader}
          data={data}
          metaData={metaTransaction}
          handlePayment={handlePayment}
        />
      )}
    </div>
  );
};
export default MetaTransactionComponent;
