import userReducer from "./user-reducer";
import forgotPasswordReducer from "./forgot-password";
import layoutReducer from "./layout-reducer";
import alertReducer from "./alert-reducer";

import { combineReducers } from "redux";
import orderReducer from "./order-reducer";
import dashboardReducer from "./dashboard-reducer";
import mineReducer from "./mine-reducer";
import secondarySaleReducer from "./secondary-sale-reducer";
import paymentReducer from "./payment-reducer";
import redeemReducer from "./redeem-reducer";

const rootReducer = combineReducers({
  userReducer,
  forgotPasswordReducer,
  layoutReducer,
  alertReducer,
  orderReducer,
  dashboardReducer,
  mineReducer,
  secondarySaleReducer,
  paymentReducer,
  redeemReducer,
});

export default rootReducer;
