import QrCodeForm from "../../../Common/QRCodeComponent";

const MFAModelComponent = ({ hide, setIsOn }: any) => {
  return (
    <div className="container font-w-600">
      <h1 className="font-30 font-w-500 crypto-dinar-white mt-lg-5 text-center">
        Google Authenticator
      </h1>
      {/* <p className="font-16 font-w-400 font-999FAE mb-lg-5 text-center">
          Scan the Code Below
        </p> */}
      <QrCodeForm hide={hide} setIsOn={setIsOn} />
    </div>
  );
};

export default MFAModelComponent;
