import React from "react";
import Layout from "../Layouts/Layout";
import PrivacyPolicyComponent from "../Components/PrivacyPolicyComponent";
const PrivatePolicy = () => {
  return (
    <>
      <Layout title="Private Policy">
        <PrivacyPolicyComponent />
      </Layout>
    </>
  );
};

export default PrivatePolicy;
