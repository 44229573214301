/* eslint-disable default-param-last */

import { REDEEM_TYPES } from "../redux-constant-type";

interface redeemType {
  redemption_ref: string;
  request_date: string;
  qty: number;
  platform_fee: number;
  delivery_fee: number;
  wallet_address: string;
  total_gold: number;
  status: string;
}
interface order {
  token_redeemed: number;
  weight_of_gold: number;
  redeemed_value: number;
  available_token: number;
  available_weight: number;
}
interface state {
  redeemList: redeemType[];
  redeemOrder: order;
}

const initialState: state = {
  redeemList: [],
  redeemOrder: {
    token_redeemed: 0,
    weight_of_gold: 0,
    redeemed_value: 0,
    available_token: 0,
    available_weight: 0,
  },
};

const redeemReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDEEM_TYPES.SET_REDEEM_LIST:
      return {
        ...state,
        redeemList: [...action.payload.redeemList],
        redeemOrder: action.payload.redeemOrder,
      };
    default:
      return state;
  }
};

export default redeemReducer;
