import React from "react";
import Layout from "../Layouts/Layout";
import Page404Cotainer from "../Components/Page404Component";
const Page404 = () => {
  return (
    <Layout title="Page Not found" showFooter={false}>
      <Page404Cotainer />
    </Layout>
  );
};

export default Page404;
