const metaTransactionValidation = (data: any, userWallet: string) => {
  console.log(data);
  if (data.address === "") {
    return {
      validation: true,
      message: "Please Enter Wallet Address.",
    };
  }

  if (data.amount === "") {
    return {
      validation: true,
      message: "Please Enter Amount.",
    };
  }
  if (parseFloat(data.amount) < 1) {
    return {
      validation: true,
      message: "Please Enter amount greater than 0.",
    };
  }
  if (data.address === userWallet) {
    return {
      validation: true,
      message: "User cannot send GDI token to the same account.",
    };
  }
  return {
    validation: false,
    message: "",
  };
};
export { metaTransactionValidation };
