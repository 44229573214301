import React from "react";
import { Row } from "react-bootstrap";
import Styles from "./SupportPublicDoc.module.css";
import GreyDot from "../../../Assets/Images/grey-dot.svg";
const SupportPublicDoc = () => {
  return (
    <>
      <Row>
        <div className={Styles.card}>
          <div className={Styles.header}>
            <img src={GreyDot} alt="grey dot" className="me-2" />
            Public Documentation
          </div>
          <div className={Styles.body}>File name goes here (click to view)</div>
          <div className={Styles.body}>File name goes here (click to view)</div>
          <div className={Styles.body}>File name goes here (click to view)</div>
          <div className={Styles.body}>File name goes here (click to view)</div>
        </div>
      </Row>
    </>
  );
};
export default SupportPublicDoc;
