import React from "react";
import Layout from "../Layouts/Layout";
import ChangePasswordComponent from "../Components/ChangePasswordComponent";

const ChangePassword = () => {
  return (
    <Layout title="Forgot Password">
      <ChangePasswordComponent />
    </Layout>
  );
};

export default ChangePassword;
