import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import SupportComponent from "../Components/SupportComponent";
const DistributerSupport = ({ role }) => {
  return (
    <UserLayout role={role} title="Distributer Support" showPaddding>
      <SupportComponent />
    </UserLayout>
  );
};
export default DistributerSupport;
