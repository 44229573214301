import Styles from "./RedirectPage.module.css";
import successIcon from "../../../Assets/Images/goldefi/success.svg";
import ButtonStyles from "../../../Components/Common/Button.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";

const RedirectPage = ({ order, setRedirectScreen }: any) => {
  const [success, setSuccess] = useState(true);
  const navigate = useNavigate();

  return (
    <div className={Styles.container}>
      {/* <div className="d-flex justify-content-center text-center">
        <img src={successIcon} alt="transaction" />
      </div> */}
      <div className={Styles.textContainer}>
        <div
          className={(success ? Styles.success : "") + " " + Styles.txStatus}>
          Receipt received
        </div>
        {!success ? (
          <div className={Styles.orderInfo}>Order ID - {order.order_ref}</div>
        ) : (
          <div className={Styles.successText}>
            <div className={Styles.text2}>Success!!!</div>
            We have successfully received your input regarding payment receipt.
          </div>
        )}
        {!success ? (
          <div className={Styles.text}>
            <>
              A request has been initiated to transfer funds from your Metamask
              wallet. Kindly log in to your Metamask account and authorize the
              request.
              <br />
              <br />
              Once the transaction is completed, the tokens will be minted and
              transferred to your GolDeFi account.
            </>
          </div>
        ) : (
          <div className={Styles.successContainer}>
            <div className={Styles.successText2}>
              Order ID - {order.order_ref}
            </div>
            <div className={Styles.successCardContainer}>
              <div className={Styles.successCard}>
                <div className={Styles.txtHead}>Tokens Assigned</div>
                <div className={Styles.txtSub}>{order.qty}</div>
              </div>
              <div className={Styles.successCard}>
                <div className={Styles.txtHead}>Amount Received</div>
                <div className={Styles.txtSub}>
                  <CurrencyFormat
                    value={order.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </div>
              </div>
              <div className={Styles.successCard}>
                <div className={Styles.txtHead}>Consumer</div>
                <div className={Styles.txtSub}>{order.consumer}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={Styles.textContainer}>
        <div className={Styles.btnContainer}>
          <button
            type="submit"
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} ${Styles.btn}`}
            onClick={() => navigate("/payment-recipts")}>
            View Payments
          </button>
        </div>
        <div>
          <Link
            onClick={() => setRedirectScreen(false)}
            to="/search-orders"
            className={Styles.link}>
            Search for an order
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RedirectPage;
