import React from 'react';
import Layout from '../Layouts/Layout';
import CheckEmailComponent from "../Components/CheckEmailComponent";

const CheckEmail = () => {

  return (
    <>
      {/* <Layout title="Forgot Password"> */}
        <CheckEmailComponent />
      {/* </Layout> */}
    </>
  );
};

export default CheckEmail;