import React from 'react';
import Layout from '../Layouts/Layout';
import ConfirmPasswordComponent from "../Components/ConfirmPasswordComponent";

const ConfirmPassword = () => {
   

    return (
        <>
            {/* <Layout title="Confirm Password"> */}
                <ConfirmPasswordComponent />
            {/* </Layout> */}
        </>
    );
};

export default ConfirmPassword;