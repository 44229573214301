//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { bankDetailAPI, bankTransactionAPI } from "../../../service/api";
import ButtonStyles from "../../Common/Button.module.css";
import Styles from "./TransactionModalComponent.module.css";
import { LuCopy } from "react-icons/lu";
import { FiExternalLink } from "react-icons/fi";
import InputField from "../../Common/InputField";
import {
  GetOrderStatus,
  copyText,
  getDateFormat,
  getShortName,
} from "../../../utils/common";

interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  data: any;
}

const TransactionModalComponent = ({
  showModal,
  setShowModal,
  data,
}: Props) => {
  const DesktopOrderTable = ({ info, idx, name }: any) => {
    if (!info.time) {
      return;
    }
    const date = info.time ? getDateFormat(info.time.split(" ")[0]) : "N/A";

    return (
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-12 mx-3 px-3 mb-3">
            {idx === 1 && (
              <div className="d-flex justify-content-between">
                <div className="tableHeading col px-1 fs-6">
                  Order Date / Reference
                </div>
                {/* <div className="tableHeading col px-1 fs-6">Order Id</div> */}
                <div className="tableHeading col px-1 fs-6">
                  Redeem Quantity
                </div>
                <div className="tableHeading col px-1 fs-6">
                  Non-Refundable Fees
                </div>
                <div className="tableHeading col px-1 fs-6">Owner</div>
                <div className="tableHeading col px-1 fs-6">
                  Transaction Type
                </div>
                <div className="tableHeading col px-1 fs-6">
                  Transaction Hash
                </div>
                <div className="tableHeading col px-1 fs-6">Status</div>
              </div>
            )}
          </div>
          <div
            key={`order-+${idx}`}
            className={
              idx % 2
                ? "col-md-12 mx-3 tableRowDark py-4 px-3 mt-1"
                : "col-md-12 mx-3 tableRowLight py-4 px-3 mt-1"
            }>
            <div className="d-flex justify-content-between">
              <div className=" tableText col px-1 fs-6">
                <div className="tableHeading">{date}</div>
                <div> / {data.redeemId}</div>
              </div>
              <div className="tableText col px-1 fs-6">
                {data.redeemQuantity}
              </div>
              <div className="tableText col px-1 fs-6">
                {data.nonRefundable}
              </div>
              <div className="tableText col px-1 fs-6">
                {/* {getShortName(data.wallet_address, false)} */}
                {data.wallet_address ? (
                  <span className="d-flex gap-2 ">
                    {getShortName(data.wallet_address, false)}{" "}
                    <LuCopy
                      className="cursor-pointer"
                      onClick={() => copyText(data.wallet_address)}
                    />
                  </span>
                ) : (
                  "N/A"
                )}
              </div>
              <div className="tableText col px-1 fs-6">{name}</div>
              <div className="tableText col px-1 fs-6">
                {info.txn_has ? (
                  <a
                    className="d-flex gap-2"
                    href={info.txn_link}
                    target="_blank">
                    {getShortName(info.txn_has, false)}
                    <FiExternalLink />
                  </a>
                ) : (
                  "N/A"
                )}
              </div>
              <div className="tableText col px-1 fs-6">
                {GetOrderStatus(data.status)}
              </div>
              {/* <div className="tableText col px-1 fs-6">{d.payment_mode}</div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const MobileOrderCard = ({ info, idx }: any) => {
    if (!info.time) {
      return;
    }
    const date = info.time ? getDateFormat(info.time.split(" ")[0]) : "N/A";

    return (
      <div
        key={`order-card-+${idx}`}
        className={
          idx % 2
            ? "row orderCardDark py-3 px-2 mb-2"
            : "row orderCardLight py-3 px-2 mb-2"
        }>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start">
            <div className="col-md-12"> Order Date / Reference</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            <div className="col-md-12">
              {" "}
              {date} / {data.redeemId}{" "}
            </div>
          </div>
        </div>
        <hr className="px-auto mt-1 text-white" />
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">Redeem Quantity</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            <div className="col-md-12">{data.redeemQuantity}</div>
          </div>
        </div>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">Non-Refundable Fees</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            <div className="col-md-12">{data.nonRefundable}</div>
          </div>
        </div>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">Owner</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            <div className="col-md-12">
              {" "}
              {data.wallet_address ? getShortName(data.wallet_address) : "N/A"}
            </div>
          </div>
        </div>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">Transaction Hash</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            <div className="col-md-12">
              {info.txn_has ? (
                <>
                  <a href={info.txn_link} target="_blank">
                    {getShortName(info.txn_has, false)}
                  </a>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">Status</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            <div className="col-md-12">{GetOrderStatus(data.status)}</div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {showModal && (
        <Modal
          size="xl"
          centered
          show={showModal}
          onHide={() => setShowModal(false)}
          className={Styles.modalContainer}>
          <Modal.Body className={Styles.modalBody}>
            <Row className={Styles.header1}>Transaction Detail</Row>
            <Row className="d-none d-md-block d-xl-block ">
              <DesktopOrderTable
                info={data.transactions.create_redemption_request}
                idx={1}
                name="Create Redemption Process"
              />
            </Row>
            <Row className="d-block d-xl-none	 d-md-none ">
              <MobileOrderCard
                info={data.transactions.create_redemption_request}
                idx={1}
                name="Create Redemption Process"
              />
            </Row>
            <Row className="d-none d-md-block d-xl-block ">
              <DesktopOrderTable
                info={data.transactions.process_redemption_request}
                idx={2}
                name="Process Redemption Process"
              />
            </Row>
            <Row className="d-block d-xl-none	 d-md-none ">
              <MobileOrderCard
                info={data.transactions.process_redemption_request}
                idx={2}
                name="Process Redemption Process"
              />
            </Row>
            <Row className="d-none d-md-block d-xl-block ">
              <DesktopOrderTable
                info={data.transactions.review_redemption_request}
                idx={3}
                name="Review Redemption Process"
              />
            </Row>
            <Row className="d-block d-xl-none	 d-md-none ">
              <MobileOrderCard
                info={data.transactions.review_redemption_request}
                idx={3}
                name="Review Redemption Process"
              />
            </Row>
            <Row className="d-none d-md-block d-xl-block ">
              <DesktopOrderTable
                info={data.transactions.burn_redemption_request}
                idx={4}
                name="Burn Redemption Process"
              />
            </Row>
            <Row className="d-block d-xl-none	 d-md-none ">
              <MobileOrderCard
                info={data.transactions.burn_redemption_request}
                idx={4}
                name="Burn Redemption Process"
              />
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default TransactionModalComponent;
