import React from "react";
import Layout from "../Layouts/Layout";
import SearchOrdersComponent from "../Components/SearchOrdersComponent";
const SearchOrders = () => {
  return (
    <Layout>
      <SearchOrdersComponent />
    </Layout>
  );
};

export default SearchOrders;
