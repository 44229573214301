import { Chain, createThirdwebClient, getContract, prepareContractCall, resolveMethod, sendTransaction } from "thirdweb";
import { createWallet, injectedProvider, WalletId } from "thirdweb/wallets";
import { defineChain } from "thirdweb/chains";
import { Ethereum } from "thirdweb/dist/types/wallets/interfaces/ethereum";
import notify from "../utils/notify";
import { SHA256, enc } from 'crypto-js';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useActiveWallet, useDisconnect, useSetActiveWallet } from "thirdweb/react";
const { SmartContractABI } = require("../service/abi/new-abi");
const { MetaTransactionABI } = require("../service/abi/new-usdt-abi.json");
const { SecondSmartContract } = require("../service/abi/second-smart-contract");

const clientID = process.env.REACT_APP_THIRDWEB_CLIENT_ID as string;

export const getClient = () => (createThirdwebClient({
    clientId: clientID
}))

export const getWallets = () => ([
    createWallet('io.metamask'),
    // createWallet('me.rainbow'),
    // createWallet('com.coinbase.wallet'),
]);


export const getProvider = (id: WalletId = 'io.metamask') => {
    return injectedProvider(id);
}

export const isWalletInstalled = (id: WalletId = 'io.metamask') => {
    const walletProvider = getProvider(id);
    return !!walletProvider;
}

export const connectWallet = async (id: WalletId = 'io.metamask', chain?: Chain) => {
    const wallet = createWallet(id);

    await wallet.connect({
        client: getClient(),
        chain
    });

    return wallet;
}

export const useWalletConnect = () => {
    const wallet_address = useSelector((state: any) => state.userReducer.userDetails.wallet_address ?? false);
    const activeWallet = useActiveWallet();
    const setActiveWallet = useSetActiveWallet()
    const { disconnect } = useDisconnect()

    useEffect(() => {
        if (wallet_address === false || wallet_address.length <= 0) {
            if (activeWallet) {
                disconnect(activeWallet)
            }
        } else {
            connectWallet('io.metamask', getChains()).then(async (wallet) => {
                await setActiveWallet(wallet);
            }).catch(error => {
                console.log(error);
            });
        }
    }, [wallet_address])
}


export const getWalletAddresses = (provider: Ethereum) => {
    return provider.request({
        method: 'eth_requestAccounts',
    });
}

export const getWalletAccount = async () => {
    const isInstalled = await isWalletInstalled('io.metamask');

    if (!isInstalled) return { error: true, message: 'MetaMask is not installed!' };

    const metamaskProvider = getProvider('io.metamask') as Ethereum;

    const accounts = await getWalletAddresses(metamaskProvider)

    if (accounts.length === 0) return { error: true, message: "No active account found!" }

    return { error: false, accountAddress: accounts[0] };
}

export const checkMetaMaskAccount = async (userWalletAddress: string = "") => {
    try {
        const currentWalletAccount = await getWalletAccount()

        if (currentWalletAccount.error) {
            notify.error("MetaMask not detected");
            return { connected: false };
        }

        if (!currentWalletAccount.accountAddress) {
            notify.error("MetaMask doesn't have any account");
            return { connected: false };
        }

        if (userWalletAddress !== "" && currentWalletAccount.accountAddress !== userWalletAddress.toLocaleLowerCase()) {
            notify.error("Connected wallet is different from user profile wallet");
            return { connected: false };
        }

        return { connected: true };

    } catch {
        notify.error('Error Connecting to MetaMask');
        return { connected: false };
    }

}

export const getChains = (name: string = "") => {

    const chain = defineChain({
        id: parseInt(process.env.REACT_APP_CHAIN_ID as string),
        name: process.env.REACT_APP_NETWORK_NAME as string,
        nativeCurrency: {
            decimals: 18,
            name: process.env.REACT_APP_CURRENCY_SYMBOL as string,
            symbol: process.env.REACT_APP_CURRENCY_SYMBOL as string
        },
        rpc: process.env.REACT_APP_RPC_URL as string,
        blockExplorers: [{ name: process.env.REACT_APP_NETWORK_NAME as string, url: process.env.REACT_APP_BLOCK_EXPLORER_URLS as string }]
    });

    const chainMatic = defineChain({
        id: parseInt(process.env.REACT_APP_CHAIN_ID_MATIC as string),
        name: process.env.REACT_APP_NETWORK_NAME_MATIC as string,
        nativeCurrency: {
            decimals: 18,
            name: process.env.REACT_APP_CURRENCY_SYMBOL_MATIC as string,
            symbol: process.env.REACT_APP_CURRENCY_SYMBOL_MATIC as string
        },
        rpc: process.env.REACT_APP_RPC_URL_MATIC as string,
        blockExplorers: [{ name: process.env.REACT_APP_NETWORK_NAME_MATIC as string, url: process.env.REACT_APP_BLOCK_EXPLORER_URLS_MATIC as string }]
    });

    if (name === '') return chain

    return chainMatic
}

export const createRedemption = async (userAddress: string, contractAddress: string, redemptionID: string, amount: number, nonRefundableFee: number) => {

    try {
        const check = await checkMetaMaskAccount(userAddress)
        if (!check.connected) throw new Error('Wallet not connnected');

        const polygonChain = getChains('polygon');

        const contract = getContract({
            client: getClient(),
            chain: polygonChain,
            address: contractAddress,
            abi: SecondSmartContract
        });

        const contractTransaction = prepareContractCall({
            contract,
            method: resolveMethod("createRedemptionRequest"),
            params: [toBytes32(redemptionID), amount * 100],
        });

        const wallet = await connectWallet('io.metamask', polygonChain);
        const account = wallet.getAccount();

        if (!account) throw new Error('No account was found!');

        const transction = await sendTransaction({
            account,
            transaction: contractTransaction
        })

        return { result: transction.transactionHash, error: false };
    } catch (error) {
        console.log(error);
        return handleTransactionError(error)
    }
}



export const processRedeemRequest = async (userAddress: string, contractAddress: string, redemptionID: string) => {

    try {
        const check = await checkMetaMaskAccount(userAddress)
        if (!check.connected) throw new Error('Wallet not connnected');

        const polygonChain = getChains('polygon');

        const contract = getContract({
            client: getClient(),
            chain: polygonChain,
            address: contractAddress,
            abi: SecondSmartContract
        });

        const contractTransaction = prepareContractCall({
            contract,
            method: resolveMethod("processRedemptionRequest"),
            params: [toBytes32(redemptionID)],
        });

        const wallet = await connectWallet('io.metamask', polygonChain);
        const account = wallet.getAccount();

        if (!account) throw new Error('No account was found!');

        const transction = await sendTransaction({
            account,
            transaction: contractTransaction
        })

        return { result: transction.transactionHash, error: false };

    } catch (error) {
        console.log(error);
        return handleTransactionError(error)
    }
}


export const transferToken = async (userAddress: string, data: number, contractAddress: string, adminAddress: string) => {

    try {
        const check = await checkMetaMaskAccount(userAddress)
        if (!check.connected) throw new Error('Wallet not connnected');

        const defaultChain = getChains('');

        const contract = getContract({
            client: getClient(),
            chain: defaultChain,
            address: contractAddress,
            abi: SmartContractABI
        });
       
        const amount = BigInt(Math.floor(data * 10 ** 6));

        const contractTransaction = prepareContractCall({
            contract,
            method: resolveMethod("transfer"),
            params: [adminAddress, amount],
        });

        const wallet = await connectWallet('io.metamask', defaultChain);
        const account = wallet.getAccount();

        if (!account) throw new Error('No account was found!');

        const transction = await sendTransaction({
            account,
            transaction: contractTransaction
        })

        return { result: transction.transactionHash, error: false };

    } catch (error) {
        console.log(error);
        return handleTransactionError(error)
    }
}


export const directTranferToken = async (userAddress: string, data: number, contractAddress: string, adminAddress: string) => {

    try {

        const check = await checkMetaMaskAccount(userAddress)
        if (!check.connected) throw new Error('Wallet not connnected');

        const polygonChain = getChains('polygon');

        const contract = getContract({
            client: getClient(),
            chain: polygonChain,
            address: contractAddress,
            abi: MetaTransactionABI
        });

        const amount = BigInt(data * 10 ** 2);

        const contractTransaction = prepareContractCall({
            contract,
            method: resolveMethod("transfer"),
            params: [adminAddress, amount],
        });

        const wallet = await connectWallet('io.metamask', polygonChain);
        const account = wallet.getAccount();

        if (!account) throw new Error('No account was found!');

        const transction = await sendTransaction({
            account,
            transaction: contractTransaction
        })

        return { result: transction.transactionHash, error: false };

    } catch (error) {
        console.log(error);
        return handleTransactionError(error)
    }
}


export const signMessage = async (userAddress: string, message: string, chain?: Chain) => {

    try {
        const check = await checkMetaMaskAccount(userAddress)
        if (!check.connected) throw new Error('Wallet not connnected');


        const wallet = await connectWallet('io.metamask',chain);
        const account = wallet.getAccount();

        if (!account) throw new Error('No account was found!');

        const signature = await account.signMessage({
            message
        });

        return { result: signature, error: false };
    } catch (error) {
        console.log(error);
        return handleTransactionError(error)
    }
}


export const createRedemptionSign = async (userAddress: string, data: { message: string, types: any, domain: string, primaryType: string }, chain?: Chain) => {
    try {

        const check = await checkMetaMaskAccount(userAddress)
        if (!check.connected) throw new Error('Wallet not connnected');

        const wallet = await connectWallet('io.metamask', chain);
        const account = wallet.getAccount();

        if (!account) throw new Error('No account was found!');

        const signature = await account.signTypedData({
            message: data.message as any,
            types: data.types,
            domain: data.domain as any,
            primaryType: data.primaryType
        });

        return { result: signature, error: false };
    } catch (error) {
        console.log(error);
        return handleTransactionError(error)
    }
}

export const userSign = async (amount: number, date: string, order: any, chain?: Chain) => {

    try {
        const check = await checkMetaMaskAccount()
        if (!check.connected) throw new Error('Wallet not connnected');

        const wallet = await connectWallet('io.metamask', chain);
        const account = wallet.getAccount();

        if (!account) throw new Error('No account was found!');

        const message = `This is to confirm that amount of ${amount} is received at date ${date} and is submitted by Distributor ${order.distributor} for Sale Order ${order.order_ref} and User ${order.consumer}`;

        const signature = await account.signMessage({
            message
        });

        return { result: signature, error: false };

    } catch (error) {
        console.log(error);
        return handleTransactionError(error)
    }
}


const handleTransactionError = (error: any) => {
    if (error.code === "ACTION_REJECTED" || error.code == 4001) {
        notify.error("User rejected the transaction.");
        return { error: error.code, result: null };
    } else if (error.code === "CALL_EXCEPTION") {
        notify.error("User does not have enough tokens.");
        return { error: error.code, result: null };
    } else {
        return { error: error?.code ?? error.message, result: null };
    }
};


//Utils
const toBytes32 = (str: string) => {
    if (str.length > 32) {
        throw new Error("String is too long to convert to bytes32");
    }

    let hexStr = "0x";
    for (let i = 0; i < str.length; i++) {
        const hex = str.charCodeAt(i).toString(16);
        hexStr += hex.length === 1 ? "0" + hex : hex;
    }

    while (hexStr.length < 66) {
        hexStr += "0";
    }

    return hexStr;
};

export const generateWalletAddress = (username: string) => {
    const encodedData = username;
    const hashedData = SHA256(encodedData).toString(enc.Hex);
    const addressBytes = "0x" + hashedData.slice(0, 40);
    const treasuryAddress = addressBytes;
    return treasuryAddress;
};
