import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Style from "./getQuote.module.css";
import ButtonStyles from "../../../Components/Common/Button.module.css";
import {
  redemptionRequestAPI,
  relayerProcessRedemption,
  updateTransactionHashAPI,
} from "../../../service/api";
import notify from "../../../utils/notify";
import metamask from "../../../utils/metamask";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../redux/actions/layout-action";
import { createRedemptionSign, getChains } from "../../../service/thirdweb_service";

const GetQuoteModal = ({ show, onHide, data, fetchData }: any) => {
  const { userDetails } = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch();
  const redeemRequestFuntion = async (e = 0, signature = "") => {
    const reqBody = {
      redemption_ref: data.redemption_ref,
      state: e,
    };
    const resp: any = await redemptionRequestAPI(reqBody);
    fetchData();
    onHide();
    if (resp.status) {
      notify.success(resp.message);
      if (e === 1) {
        return resp.toSign;
      } else {
        return false;
      }
    } else {
      notify.error(resp.message);
    }
  };
  const redemptionRequest = async (e = 0) => {
    try {
      dispatch(setLoader(true));
      if (e === 0) {
        await redeemRequestFuntion(e, "");
        dispatch(setLoader(false));

        return;
      }

      const res: any = await redeemRequestFuntion(e);
      if (res) {
        const { request, ...rest } = res.payload;
        const updatedPayload = { ...rest, message: request };
        const resp = await createRedemptionSign(
          userDetails.wallet_address,
          updatedPayload,
          getChains('polygon')
        );
        if (resp.result) {
          const info = {
            redemptionID: data.hex_redemptionId,
            user_wallet: userDetails.wallet_address,
            signature: resp.result,
            deadline: updatedPayload.message.deadline,
          };
          const reply: any = await relayerProcessRedemption(info);

          if (reply.status === 1) {
            const date = Date.now();
            const body = {
              redemption_ref: data.redemption_ref,
              active: "process",
              transaction_hash: reply.hash,
              time_stamp: date,
            };
            const response: any = await updateTransactionHashAPI(body);
            if (response.status) {
              notify.success(response.message);
            }
          } else {
            notify.error(
              "Something went wrong, Please try again after sometime."
            );
          }
        }
      }
      dispatch(setLoader(false));
    } catch (error) {
      console.log("redeem api error", error);
    }
  };
  return (
    <>
      {data && (
        <Modal centered={true} size="lg" show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Col>
              <div className={Style.header}>Redemption Request Quote</div>
            </Col>
          </Modal.Header>
          <Modal.Body className="p-5">
            <Row className="mb-5">
              <Col>
                <div className={Style.subheader}>
                  We have received a quote for your redemption request from the
                  Vault. Please review the details below:
                  <br />
                  Amount to be Redeemed: <b>{data.total_gold.toFixed(5)} </b>
                  Troy oz ({data.qty} Tokens)
                  <br />
                  Non-refundable : <b>{data.non_refundable_fee} </b>
                  <br />
                  Platform Fees:<b> {data.platform_fee.toFixed(2)} </b>
                  <br />
                  Vault Fees: <b>{data.delivery_fee.toFixed(2)}</b> (Includes
                  shipping, insurance, etc.)
                  <br />
                  Total fees (in GDI tokens, excluding Non-Refundable Fees):{" "}
                  <b> {(data.delivery_fee + data.platform_fee).toFixed(2)} </b>
                  <br />
                  Total to be paid (Redemption amount + Fees, in GDI tokens):
                  <b>
                    {(data.qty + data.delivery_fee + data.platform_fee).toFixed(
                      2
                    )}{" "}
                  </b>
                  <br />
                  <br />
                  To proceed with the redemption, you need to accept the quote.
                  If you choose to decline, your redemption request will be
                  canceled.
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={3} md={3} sm={6} xs={6}>
                <button
                  className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeWhiteBackground} me-3 `}
                  onClick={() => redemptionRequest(0)}>
                  Reject
                </button>
              </Col>{" "}
              <Col lg={3} md={3} sm={6} xs={6}>
                <button
                  className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} `}
                  onClick={() => redemptionRequest(1)}>
                  Accept
                </button>
              </Col>{" "}
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default GetQuoteModal;
