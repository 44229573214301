/* eslint-disable react-hooks/exhaustive-deps */
import Styles from "./resellCollection.module.css";
import mine1 from "../../Assets/Images/min1.jpeg";
import { useEffect, useState } from "react";
import { Modal } from "../Common/Modal";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { getMyResellMineData, withdrawMine } from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { MineResellType } from "../../redux/reducers/mine-reducer";
import { setMineResellList } from "../../redux/actions";
import notify from "../../utils/notify";
import { useNavigate } from "react-router-dom";

const ResellCollectionBody = () => {
  const navigate = useNavigate();

  const [dataLoading, setDataLoading] = useState(true);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [selectedMine, setSelectedMine] = useState({} as MineResellType);
  const [sellQty, setSellQty] = useState(0);

  const dispatch = useDispatch();
  const { mineResellList } = useSelector((state: any) => state.mineReducer);

  const getMyResellCollection = async () => {
    try {
      const res: any = await getMyResellMineData();
      if (res.status) {
        dispatch(setMineResellList(res.data));
        setDataLoading(false);
      }
    } catch (error) {
      console.log("eerr", error);
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getMyResellCollection();
  }, []);

  const handleWithdraw = async () => {
    setShowWithdrawModal(false);
    try {
      const data = {
        nft_id: selectedMine.nft_id,
        qty: sellQty,
      };
      const res: any = await withdrawMine(data);
      if (res.status) {
        notify.success("Mine added fro resell");
        navigate("/my-collection");
      }
    } catch (error) {
      console.log("eerr", error);
      notify.error("Error reselling mine");
    }
  };

  const Panel = ({ data }: any) => {
    return (
      <div className={Styles.panelContainer + " col-12"}>
        <div className="row px-2">
          <div className="col-lg-2 col-md-2 ">
            <div className={Styles.imgDiv}>
              <img
                src={data?.mine_images?.[0]?.mine_image_url || mine1}
                className={Styles.img}
                alt="panel-img"></img>
            </div>
          </div>
          <div className="col-lg-10 col-md-10">
            <div className={Styles.collectionDiv}>
              <div className="row">
                <div className="col-lg-10 col-md-10">
                  <div className="d-flex j">
                    <div className={Styles.panelHeader}>{data.name}</div>
                    <div className=" my-auto pt-2">
                      <span className="mx-3  badge bg-warning text-dark badge-sm  rounded-pill">
                        {data.type === "own" ? "My Wallet" : "Custodian Wallet"}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-lg-3 col-md-3">
                      <div className={Styles.panelHeading}>Quantity</div>
                      <div className={Styles.panelValue}>
                        {data?.available_qty}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className={Styles.panelHeading}>
                        Expected Mine End At
                      </div>
                      <div className={Styles.panelValue}>
                        {data?.mine_expected_end_date
                          ? moment(data?.mine_expected_end_date).format("lll")
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                      <div className={Styles.panelHeading}>Sale Price</div>
                      <div className={Styles.panelValue}>
                        <CurrencyFormat
                          value={data?.sale_price || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 my-auto">
                  <div>
                    <button
                      className={Styles.btn1}
                      onClick={() => {
                        setSelectedMine(data);
                        setShowWithdrawModal(true);
                      }}>
                      Withdraw
                    </button>
                  </div>
                  {/* <div>
                    <button
                      className={Styles.btn2}
                      onClick={() => {
                        setSelectedMine(data);
                        setShowRedeemModal(true);
                      }}
                    >
                      Redeem
                    </button>
                  </div> */}
                  <div className="mt-2 my-auto">
                    <button className={Styles.btn3}>View Token</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="col-12">
      <div className={Styles.container}>
        <div className={Styles.headerContainer}>
          <div className={Styles.header}>Resell Request</div>
        </div>
        <div className={Styles.collectionPanel}>
          <div className={Styles.collectionPanelBody}>
            {mineResellList && mineResellList.length > 0 ? (
              mineResellList.map((d: MineResellType) => (
                <Panel data={d} key={d.mine_asset_id} />
              ))
            ) : (
              <div className="text-white text-center mt-4">
                <h5>
                  {" "}
                  {dataLoading ? "loading !!!" : " No Resell Request Present "}
                </h5>
              </div>
            )}
          </div>
          <div className={Styles.collectionPanelFooter}></div>
        </div>
      </div>
      {/* Withdraw Modal */}
      <Modal
        show={showWithdrawModal}
        onHide={() => {
          setShowWithdrawModal(false);
        }}
        title=""
        confirmBtnText="Withdraw"
        confirmBtnClassName={` justify-content-center mx-auto  ${Styles.btn1}`}
        visibleFooter
        visibleHeaderCloseIcon
        onConfirm={() => {
          handleWithdraw();
        }}
        visibleConfirmBtn={selectedMine.available_qty >= sellQty}
        visibleFooterCloseButton={false}>
        <div className="px-3 mt-1">
          <h4 className="text-left">Withdraw Request</h4>
          <div className="row">
            <div className="mt-3">
              <label htmlFor="availableQty" className="form-label">
                Available Quantity
              </label>
              <input
                type="number"
                className="form-control"
                id="availableQty"
                disabled
                value={selectedMine?.available_qty || 0}
              />
            </div>
          </div>
          <div className="row">
            <div className="mt-3">
              <label htmlFor="resellavailablePrice" className="form-label">
                token Value
              </label>
              <input
                type="number"
                className="form-control"
                id="resellavailablePrice"
                placeholder="resell quantity"
                defaultValue={selectedMine.sale_price}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="mt-3">
              <label htmlFor="resellavailableQty" className="form-label">
                Enter Withdraw Quantity
              </label>
              <input
                type="number"
                className="form-control"
                id="resellavailableQty"
                placeholder="resell quantity"
                defaultValue={1}
                max={selectedMine?.available_qty}
                onChange={(e) => {
                  setSellQty(Number(e.target.value));
                }}
              />
            </div>
          </div>
          <div>
            {sellQty > selectedMine?.available_qty && (
              <p className="text-danger text-center mt-1 ">
                Quantity can't be more than available.
              </p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ResellCollectionBody;
