import React from "react";
import Styles from "./passwordValidation.module.css";
import passwordValidationIcon from "../../../../Assets/Images/goldefi/PasswordValidationIcons.svg";
import validpasswordIcon from "../../../../Assets/Images/goldefi/ValidPasswordIcons.svg";

const PasswordValidation = ({ show, validPassword }: any) => {
  return (
    <div className={Styles.validationBlock} style={show ? {display: "block"} : {display: "none"}}> 
      Your password must contain:
      <div className={Styles.validationList}>
        <img className={Styles.imgIcon} src={validPassword && validPassword['valid1'] ? validpasswordIcon : passwordValidationIcon} alt="validation" width={16}/>
        {" "}At least 8 Characters
      </div>
      <div className={Styles.validationList}>
        <img className={Styles.imgIcon} src={validPassword && validPassword['valid2'] ? validpasswordIcon : passwordValidationIcon} alt="validation" width={16}/>
        {" "}Upper case letters (A-Z)
      </div>
      <div className={Styles.validationList}>
        <img className={Styles.imgIcon} src={validPassword && validPassword['valid3'] ? validpasswordIcon : passwordValidationIcon} alt="validation" width={16}/>
        {" "}Lower case letters (a-z)
      </div>
      <div className={Styles.validationList}>
        <img className={Styles.imgIcon} src={validPassword && validPassword['valid4'] ? validpasswordIcon : passwordValidationIcon} alt="validation" width={16}/>
        {" "}At least one Numbers (0-9)
      </div>
      <div className={Styles.validationList}>
        <img className={Styles.imgIcon} src={validPassword && validPassword['valid5'] ? validpasswordIcon : passwordValidationIcon} alt="validation" width={16}/>
        {" "}At least one Special character
      </div>
    </div>
  );
};
export default PasswordValidation;
