import URLS from "../../utils/api-end-points";
import apiServices from "../api_service";

const loginAPI = async (val: any) => {
  const url = URLS.LOGIN_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const distributorLoginAPI = async (val: any) => {
  const url = URLS.DISTRIBUTOR_LOGIN_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const resetPasswordAPI = async (val: any) => {
  const url = URLS.RESET_PASSWORD;
  const result = await apiServices.post(url, val);
  return result;
};

const userDetailAPI = async () => {
  const url = URLS.USER_DETAIL_URL;
  const result = await apiServices.post(url);
  return result;
};
const userGetKYCAPI = async (val = {}) => {
  const url = URLS.GET_KYC_STATUS;
  const result = await apiServices.post(url, val);
  return result;
};
const makePayment = async (body: any) => {
  const url = URLS.STRIP_PAYMENT;
  const result = await apiServices.post(url, body);
  return result;
};

const confirmForgotPasswordAPI = async (val: any) => {
  const url = URLS.CONFIRM_FORGOT_PASSWORD;
  const result = await apiServices.post(url, val);
  return result;
};

const forgotPasswordAPI = async (val: any) => {
  const url = URLS.FORGOT_PASSWORD_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const resendOtpAPI = async (val: any) => {
  const url = URLS.RESEND_OTP_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const signUpAPI = async (val: any) => {
  const url = URLS.SIGNUP_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const distributorVerifyAPI = async (val: any) => {
  const url = URLS.DISTRIBUTOR_VERIFY_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const distributorSignUpAPI = async (val: any) => {
  const url = URLS.DISTRIBUTOR_SIGNUP_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const verifyOtpAPI = async (val: any) => {
  const url = URLS.VERIFY_OTP_URL;
  const result = await apiServices.post(url, val);

  return result;
};

const contactUsAPI = async (val: any) => {
  const url = URLS.CONTACT_US;
  const result = await apiServices.post(url, val);

  return result;
};
const userUpdateAPI = async (val: any) => {
  const url = URLS.USER_UPDATE_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const adminDetailsAPI = async () => {
  const url = URLS.ADMIN_DETAILS;
  const result = await apiServices.post(url);
  return result;
};
const countryCodeAPI = async () => {
  const url = URLS.COUNTRY_CODES;
  const result = await apiServices.post(url);
  return result;
};

const getMineAsset = async (): Promise<any> => {
  const url = URLS.GET_MINE_ASSET;
  const result = await apiServices.post(url);

  return result;
};

const buyMineToken = async (val: any) => {
  const url = URLS.BUY_MINE_TOKEN;
  const result = await apiServices.post(url, val);

  return result;
};
const updateTransactionHash = async (val: any) => {
  const url = URLS.UPDATE_TRANSACTION;
  const result = await apiServices.post(url, val);
  return result;
};

const getOrderList = async (
  pageNo: number,
  sort_by?: string,
  sort_order?: string
) => {
  const url = URLS.ORDER_LIST;
  const result = await apiServices.post(url, {
    page_no: pageNo,
    sort_by: sort_by || "",
    sort_order: sort_order || "",
  });
  return result;
};
const getRedeemList = async (pageNo: number) => {
  const url = URLS.REDEEM_LIST;
  const result = await apiServices.post(url, { page_no: pageNo });
  return result;
};

const getSearchOrder = async (data: any) => {
  const url = URLS.SEARCH_ORDER;
  const result = await apiServices.post(url, data);
  return result;
};
const submitPayment = async (val: any) => {
  const url = URLS.SUBMIT_PAYMENT;
  const result = await apiServices.post(url, val);
  return result;
};
const getDashboardData = async () => {
  const url = URLS.DASH_API;
  const result = await apiServices.post(url);
  return result;
};
const getDocumentData = async (val: any) => {
  const url = URLS.GET_DOCUMENT;
  const result = await apiServices.post(url, val);
  return result;
};
const getFAQData = async () => {
  const url = URLS.FAQ_API;
  const result = await apiServices.get(url);
  return result;
};
const getMyCollectionData = async () => {
  const url = URLS.GET_MY_COLLECTION;
  const result = await apiServices.post(url);
  return result;
};
const getMyResellMineData = async () => {
  const url = URLS.GET_MINE_RESELL_COLLECTION;
  const result = await apiServices.post(url);
  return result;
};
const resellMine = async (val: any) => {
  const url = URLS.GET_RESELL_MINE_URL;
  const result = await apiServices.post(url, val);
  return result;
};
const withdrawMine = async (val: any) => {
  const url = URLS.GET_WITHDRAW_MINE_URL;
  const result = await apiServices.post(url, val);
  return result;
};
const redeemMine = async (val: any) => {
  const url = URLS.REDEEM_MINE;
  const result = await apiServices.post(url, val);
  return result;
};
const recoverMineToken = async (val: any) => {
  const url = URLS.RECOVER_TOKEN;
  const result = await apiServices.post(url, val);
  return result;
};
const getOrderBookDetails = async () => {
  const url = URLS.ORDER_BOOK_DETAILS;
  const result = await apiServices.post(url);
  return result;
};
const getSecondaryTradeHistory = async () => {
  const url = URLS.SECONDARY_TRADE_HISTORY;
  const result = await apiServices.post(url);
  return result;
};
const buySecondaryToken = async (val: any) => {
  const url = URLS.PURCHASE_SECONDARY_TOKEN;
  const result = await apiServices.post(url, val);
  return result;
};
const getPaymentList = async (pageNo: number) => {
  const url = URLS.PAYMENT_LIST;
  const result = await apiServices.post(url, { page_no: pageNo });
  return result;
};
const validateQrCodeAPI = async (values: any) => {
  const url = URLS.VERIFY_QRCODE;
  const result = await apiServices.post(url, values);
  return result;
};
const getQrcode = async (values: any) => {
  const url = URLS.GET_QRCODE;
  const result = await apiServices.post(url, values);
  return result;
};
const disable2Fa = async (values: any) => {
  const url = URLS.DISABLE_2FA_CODE;
  const result = await apiServices.post(url, values);
  return result;
};
const fetchSumsubToken = async () => {
  const url = URLS.SUMSUB_ACCESS_TOKEN;
  const result = await apiServices.post(url);
  return result;
};
const getRedeemProcessAPI = async () => {
  const url = URLS.REDEEMPTION_PROCESS;
  const result = await apiServices.post(url);
  return result;
};
const redeemRequestAPI = async (value: any) => {
  const url = URLS.REDEEM_REQUEST;
  const result = await apiServices.post(url, value);
  return result;
};
const bankDetailAPI = async () => {
  const url = URLS.BANK_DETAIL;
  const result = await apiServices.post(url);
  return result;
};
const bankTransactionAPI = async (value: any) => {
  const url = URLS.BANK_TRANSACTION;
  const result = await apiServices.post(url, value);
  return result;
};
const distrubtorOTPAPI = async (value: any) => {
  const url = URLS.DISTRIBUTOR_OTP;
  const result = await apiServices.post(url, value);
  return result;
};
const fetchProfessionalDetail = async () => {
  const url = URLS.PROFESSIONAL_DETAILS;
  const result = await apiServices.post(url);
  return result;
};
const fetchMintDetails = async (value: any) => {
  const url = URLS.MINT_DETAILS;
  const result = await apiServices.post(url, value);
  return result;
};
const fetchCountryCode = async (value: any) => {
  const url = URLS.COUNTRY_CODE;
  const result = await apiServices.post(url, value);
  return result;
};
const updateEmail = async (value: any) => {
  const url = URLS.UPDATE_EMAIL;
  const result = await apiServices.post(url, value);
  return result;
};
const verifyOTPUpdate = async (value: any) => {
  const url = URLS.VERIFY_EMAIL_OTP;
  const result = await apiServices.post(url, value);
  return result;
};
const distributorDetail = async (value: any) => {
  const url = URLS.DISTRIBUTOR_DETAIL;
  const result = await apiServices.post(url, value);
  return result;
};
const redemptionRequestAPI = async (value: any) => {
  const url = URLS.REDEMPTION_REQUEST;
  const result = await apiServices.post(url, value);
  return result;
};
const createMetaTransactionAPI = async (value: any) => {
  const url = URLS.CREATE_META_TRANSACTION;
  const result = await apiServices.post(url, value);
  return result;
};
const updateTransactionHashAPI = async (value: any) => {
  const url = URLS.UPDATE_TRANSACTION_HASH;
  const result = await apiServices.post(url, value);
  return result;
};
const relayerCreateRedemption = async (value: any) => {
  const url = URLS.RELAYER_CREATE_REDEMPTION;
  const result = await apiServices.post(url, value, false);
  return result;
};
const relayerProcessRedemption = async (value: any) => {
  const url = URLS.RELAYER_PROCESS_REDEMPTION;
  const result = await apiServices.post(url, value, false);
  return result;
};
const relayerSubsidizeTransaction = async (value: any) => {
  const url = URLS.RELAYER_SUBSIDIZE_TRANSACTION;
  const result = await apiServices.post(url, value, false);
  return result;
};
const updateDirectTransactionHashAPI = async (value: any) => {
  const url = URLS.UPDATE_DIRECT_TRANSACTION_HASH;
  const result = await apiServices.post(url, value);
  return result;
};
const relayerBulkTransferTransaction = async (value: any) => {
  const url = URLS.RELAYER_BULKTRANSFER_TRANSACTION;
  const result = await apiServices.post(url, value, false);
  return result;
};
export {
  resendOtpAPI,
  loginAPI,
  distributorLoginAPI,
  makePayment,
  confirmForgotPasswordAPI,
  forgotPasswordAPI,
  signUpAPI,
  verifyOtpAPI,
  contactUsAPI,
  getMineAsset,
  buyMineToken,
  userDetailAPI,
  userUpdateAPI,
  resetPasswordAPI,
  userGetKYCAPI,
  updateTransactionHash,
  getOrderList,
  getRedeemList,
  getSearchOrder,
  submitPayment,
  adminDetailsAPI,
  getDocumentData,
  getDashboardData,
  getFAQData,
  getMyCollectionData,
  getMyResellMineData,
  resellMine,
  withdrawMine,
  redeemMine,
  recoverMineToken,
  buySecondaryToken,
  getOrderBookDetails,
  getSecondaryTradeHistory,
  getPaymentList,
  validateQrCodeAPI,
  getQrcode,
  disable2Fa,
  fetchSumsubToken,
  distributorVerifyAPI,
  distributorSignUpAPI,
  getRedeemProcessAPI,
  redeemRequestAPI,
  bankDetailAPI,
  bankTransactionAPI,
  distrubtorOTPAPI,
  fetchProfessionalDetail,
  fetchMintDetails,
  fetchCountryCode,
  countryCodeAPI,
  updateEmail,
  verifyOTPUpdate,
  distributorDetail,
  redemptionRequestAPI,
  createMetaTransactionAPI,
  updateTransactionHashAPI,
  relayerCreateRedemption,
  relayerProcessRedemption,
  relayerSubsidizeTransaction,
  updateDirectTransactionHashAPI,
  relayerBulkTransferTransaction,
};
