const USER_TYPES = {
  SET_USERNAME: "SET_USERNAME",
  SET_WALLET_ADDRESS: "SET_WALLET_ADDRESS",
  SET_KYC_STATUS: "SET_KYC_STATUS",
  SET_METAMASK_INSTALLED_STATUS: "SET_METAMASK_INSTALLED_STATUS",
  SET_IS_WALLET_CONNECTED: "SET_IS_WALLET_CONNECTED",
  SET_IS_LOGGED_IN: "SET_IS_LOGGED_IN",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  SET_USER_LOGOUT: "USER_LOGOUT",
  SET_KYC_REASON: "SET_KYC_REASON",
};

export { USER_TYPES };
