import Modal from "react-bootstrap/Modal";
import ButtonStyles from "../../Common/Button.module.css";
import { IoWarningSharp } from "react-icons/io5";
const ThresholdModalComponent = ({ show, onHide, onConfirm }: any) => {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      centered={true}
      size="lg"
      show={show}
      onHide={onHide}>
      <Modal.Body>
        <p className="text-center my-3 regular-font">
          <div className="d-flex justify-content-center text-center mb-3">
            <IoWarningSharp size={40} color="#e8c056" />
          </div>
          This amount exceeds your yearly purchase limit. Please contact the
          support if you wish to raise the limit.
        </p>

        <div className="d-flex justify-content-center gap-4 mt-4">
          <button
            type="submit"
            onClick={() => {
              onHide();
            }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeBlue}`}>
            cancel
          </button>
          {/* <button
            type="submit"
            onClick={() => {
              onConfirm();
            }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`}>
            Confirm
          </button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ThresholdModalComponent;
