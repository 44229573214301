import React from "react";
import Styles from "./buyMore.module.css";
import CoinToken from "../../../Assets/Images/goldefi/cointoken.png";
import { useSelector } from "react-redux";
import { formatMoney } from "../../../utils/common";
import { Tooltip } from "../../Common/Tooltip";

const BuyMoreComponent = () => {
  const { total_tokens_purchased } = useSelector(
    (state: any) => state.dashboardReducer
  );
  return (
    <div className={"row px-3 py-4 " + Styles.tokenContainer}>
      <div className="col-md-8 col-sm-8 col-8 text-start">
        <div className="col-12">
          <div>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect
                x="0.500977"
                y="0.807617"
                width="34.8711"
                height="34.8711"
                rx="17.4355"
                fill="#845002"
              />
              <g clipPath="url(#clip0_396_2819)">
                <path
                  d="M27.4365 26.5757C27.4365 26.7482 27.3574 26.9132 27.2307 27.0482C27.0961 27.1682 26.922 27.2432 26.732 27.2432H12.3395C11.3024 27.2357 10.3207 26.8532 9.58446 26.1632C8.84821 25.4657 8.45237 24.5357 8.44446 23.5532V9.91066C8.44446 9.73816 8.52362 9.56566 8.65029 9.43816C8.77696 9.31816 8.95904 9.24316 9.14904 9.24316C9.33904 9.24316 9.51321 9.31816 9.64779 9.43816C9.77446 9.55816 9.85362 9.73066 9.85362 9.91066V23.5457C9.85362 24.1757 10.107 24.7757 10.582 25.2182C11.057 25.6682 11.6824 25.9082 12.3474 25.9082H26.7399C26.922 25.9082 27.0961 25.9832 27.2386 26.1032C27.3653 26.2307 27.4445 26.3957 27.4445 26.5757H27.4365Z"
                  fill="white"
                />
                <path
                  d="M13.8436 18.2432V23.5457C13.8436 23.7182 13.7645 23.8832 13.6378 24.0182C13.3765 24.2582 12.9095 24.2582 12.6482 24.0182C12.5215 23.8907 12.4424 23.7257 12.4424 23.5457V18.2432C12.4424 18.0707 12.5215 17.8982 12.6482 17.7707C12.7749 17.6507 12.957 17.5757 13.147 17.5757C13.337 17.5757 13.5111 17.6507 13.6457 17.7707C13.7724 17.8907 13.8515 18.0632 13.8515 18.2432H13.8436Z"
                  fill="white"
                />
                <path
                  d="M17.8415 16.7282V23.5457C17.8415 23.7182 17.7623 23.8832 17.6357 24.0182C17.3744 24.2582 16.9073 24.2582 16.6461 24.0182C16.5194 23.8907 16.4402 23.7257 16.4402 23.5457V16.7282C16.4402 16.5557 16.5194 16.3832 16.6461 16.2557C16.7727 16.1357 16.9548 16.0607 17.1448 16.0607C17.3348 16.0607 17.509 16.1357 17.6436 16.2557C17.7702 16.3757 17.8494 16.5482 17.8494 16.7282H17.8415Z"
                  fill="white"
                />
                <path
                  d="M21.8394 19.0007V23.5457C21.8394 23.7182 21.7603 23.8832 21.6336 24.0182C21.3723 24.2657 20.9053 24.2657 20.644 24.0182C20.5173 23.8907 20.4382 23.7257 20.4382 23.5457V19.0007C20.4382 18.8282 20.5173 18.6632 20.644 18.5282C20.7786 18.4082 20.9528 18.3332 21.1428 18.3332C21.3328 18.3332 21.499 18.4082 21.6415 18.5282C21.7682 18.6557 21.8473 18.8207 21.8473 19.0007H21.8394Z"
                  fill="white"
                />
                <path
                  d="M25.8373 15.9707V23.5457C25.8373 23.7182 25.7582 23.8832 25.6315 24.0182C25.3703 24.2657 24.9032 24.2657 24.6419 24.0182C24.5153 23.8907 24.4361 23.7257 24.4361 23.5457V15.9707C24.4361 15.7982 24.5153 15.6257 24.6419 15.4982C24.7765 15.3782 24.9507 15.3032 25.1407 15.3032C25.3307 15.3032 25.4969 15.3782 25.6394 15.4982C25.7661 15.6182 25.8453 15.7907 25.8453 15.9707H25.8373Z"
                  fill="white"
                />
                <path
                  d="M27.4207 9.91068C27.4207 10.0907 27.3573 10.2482 27.2307 10.3832L22.7577 14.6132C22.3302 15.0257 21.7444 15.2582 21.1348 15.2582C20.5252 15.2582 19.9394 15.0332 19.5119 14.6132L17.7702 12.9707C17.4298 12.6632 16.8519 12.6632 16.4957 12.9707L13.6298 15.6932C13.4002 15.9032 13.0123 15.9407 12.7432 15.7757C12.6323 15.7007 12.5452 15.6032 12.4898 15.4757C12.4344 15.3557 12.4265 15.2282 12.4502 15.0932C12.4819 14.9657 12.5452 14.8457 12.6402 14.7557L15.514 12.0407C15.9494 11.6207 16.5194 11.3957 17.1369 11.3957C17.7544 11.3957 18.3323 11.6207 18.7598 12.0407L20.4936 13.6832C20.6677 13.8407 20.8973 13.9307 21.1348 13.9307C21.3723 13.9307 21.6098 13.8407 21.7761 13.6832L26.2332 9.43818C26.3598 9.32568 26.534 9.25818 26.724 9.25818H26.7319C26.914 9.25818 27.0802 9.32568 27.2227 9.44568C27.3494 9.57318 27.4207 9.73068 27.4207 9.91068Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_396_2819">
                  <rect
                    width="19"
                    height="18"
                    fill="white"
                    transform="translate(8.43652 9.24316)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="col-12 mt-2">
          <h6 className=" text-bolder px-2">Total number of tokens</h6>
        </div>
        <div className="col-12">
          <div className="px-2">
            <div className="d-flex justify-space-between align-items-center ">
              <div className=" col align-items-center">
                <div className="text-start">
                  <Tooltip
                    anchorSelect="#total-tokens-purchased"
                    style={{
                      backgroundColor: "#00112b",
                      color: "#fff",
                      fontFamily: "Poppins",
                    }}
                    content={total_tokens_purchased}
                    allowCopy
                  />
                  <div
                    id="total-tokens-purchased"
                    className={Styles.tokenHeading}>
                    {formatMoney(total_tokens_purchased)}
                  </div>
                </div>
              </div>
              <div className="col justify-content-center align-items-center">
                <button className="btn text-black btn-warning btn-md ">
                  Buy More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-4 col-4">
        <img
          src={CoinToken}
          className={Styles.cardImg + " mt-3"}
          alt="token-img"
        />
      </div>
    </div>
  );
};
export default BuyMoreComponent;
