import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import MyProfileComponent from "../Components/MyProfileComponent";
const DistributorProfile = ({ role }) => {
  return (
    <UserLayout title="My Profile" role={role}>
      <MyProfileComponent role={role}/>
    </UserLayout>
  );
};
export default DistributorProfile;
