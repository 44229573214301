import React from 'react';
import Layout from '../Layouts/Layout';
import ForgotPasswordComponent from "../Components/ForgotPasswordComponent";

const ForgotPassword = () => {

  return (
    <>
      {/* <Layout title="Forgot Password"> */}
        <ForgotPasswordComponent />
      {/* </Layout> */}
    </>
  );
};

export default ForgotPassword;