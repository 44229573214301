// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmForgotPasswordAPI, forgotPasswordAPI } from "../../service/api";
import Styles from "./confirmPassword.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";
import InputField from "../../Components/Common/InputField";
import OtpInput from "react-otp-input";
import { maskEmail } from "../../utils/common";
import notify from "../../utils/notify";
import AuthLayout from "../../Components/Common/LayoutAuthPages";
import PasswordInputField from "../Common/PasswordInputField";

const ConfirmPasswordComponent = () => {
  // for page navigation purpose
  const navigate = useNavigate();

  // taking the email (username) from redux store
  const username = localStorage.getItem("username");

  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const [formValue, setFormValue] = useState({
    password: "",
    confirmPassword: "",
  });

  const [otp, setOtp] = useState("");

  const [formErrors, setFormErrors] = useState<Object>({});

  const [showValues, setShowValues] = useState({
    showPassword1: false,
    showPassword2: false,
  });

  const [isSubmit, setIsSubmit] = useState<Boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<String>("");

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  // for managing password hide and show for both type of password
  const handleClickShowPassword = (num: number) => {
    if (num == 1) {
      setShowValues({
        ...showValues,
        showPassword1: !showValues.showPassword1,
      });
    } else {
      setShowValues({
        ...showValues,
        showPassword2: !showValues.showPassword2,
      });
    }
  };

  // for valiadting the form inputs
  const validateForm = (formValue) => {
    const errors = {};

    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!formValue.password.trim()) {
      errors.password = "Password is required.";
    } else if (!lengthRegex.test(formValue.password.trim())) {
      errors.password = "Password must be at least 8 characters.";
    } else if (!uppercaseRegex.test(formValue.password.trim())) {
      errors.password = "Password must contain at least one uppercase letter.";
    } else if (!lowercaseRegex.test(formValue.password.trim())) {
      errors.password = "Password must contain at least one lowercase letter.";
    } else if (!numberRegex.test(formValue.password.trim())) {
      errors.password = "Password must contain at least one number.";
    } else if (!specialCharacterRegex.test(formValue.password.trim())) {
      errors.password = "Password must contain at least one special character.";
    }

    if (!formValue.confirmPassword.trim()) {
      errors.confirmPassword = "Confirm password is required.";
    } else if (formValue.confirmPassword.trim() !== formValue.password.trim()) {
      errors.confirmPassword = "Confirm password and password should be same.";
    }

    if (otp.length !== 6) {
      errors.otp = "OTP length is not valid.";
    }

    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormErrors(validateForm(formValue));
    setIsSubmit(true);
  };

  const confirmPasswordHandler = async () => {
    const data = {
      username: username,
      confirmation_code: otp,
      password: formValue.password.trim(),
    };

    try {
      const response = await confirmForgotPasswordAPI(data);

      if (response.status) {
        setOtp("");
        setFormValue({
          password: "",
          confirmPassword: "",
        });
        navigate("/password-changed-success");
      } else {
        setApiErrorMsg(response.message);
        setOtp("");
        setFormValue({
          password: "",
          confirmPassword: "",
        });
        return;
      }
    } catch (error) {
      console.error("An error occurred during confirm Password:", error);
      return;
    }
  };

  const resetPassword = async () => {
    const data = {
      username: username,
    };

    const responseData = await forgotPasswordAPI(data);
    if (responseData.status) {
      notify.success(responseData.message);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      confirmPasswordHandler();
    }
  }, [formErrors]);

  useEffect(() => {
    if (!username) {
      navigate("/login");
    }
    if (screenSize !== 0) {
      screenSize >= 768 ? setIsMobileScreen(false) : setIsMobileScreen(true);
    }
  }, [screenSize]);

  // useEffect(() => {
  //   notify.success(`A OTP (One Time Password) has been sent to ${
  //     username ? maskEmail(username) : ""
  //   }`);
  // }, []);

  return (
    <>
      {username && (
        <AuthLayout>
          <div className={Styles.loginContainer}>
            <div className={Styles.thirdLogin}>
              <div className={Styles.heading}>Reset your password</div>
              <div className={Styles.subHeading}>
                To reset your password please enter the OTP (One Time Password)
                that has been sent to your email {maskEmail(username)}
              </div>
            </div>
            <div className={Styles.loginform}>
              <form className={Styles.Form} onSubmit={handleSubmit}>
                <div>
                  <div>
                    <p className={Styles.label}>Enter OTP</p>
                  </div>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span style={{ width: "8px" }}></span>}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus={true}
                    inputStyle={Styles.InputComponent}
                    skipDefaultStyles={true}
                    inputType="number"
                    containerStyle={Styles.OTPContainer}
                  />
                </div>
                {formErrors.otp && (
                  <p className="text-danger m-0">
                    <small>{formErrors.otp}</small>
                  </p>
                )}

                <PasswordInputField
                  // label="Email"
                  value={formValue.password}
                  type="password"
                  placeholder="Enter New Password"
                  showPassword={showValues.showPassword1}
                  onChange={changeHandler}
                  handleTogglePassword={() => handleClickShowPassword(1)}
                  height="50px"
                  name="password"
                  maxLength={40}
                  dark={true}
                  showValid={true}
                />
                {formErrors.password && (
                  <p className="text-danger m-0">
                    <small>{formErrors.password}</small>
                  </p>
                )}

                <PasswordInputField
                  // label="Email"
                  value={formValue.confirmPassword}
                  type="password"
                  placeholder="Confirm New Password"
                  showPassword={showValues.showPassword2}
                  onChange={changeHandler}
                  handleTogglePassword={() => handleClickShowPassword(2)}
                  height="50px"
                  name="confirmPassword"
                  maxLength={40}
                  dark={true}
                />
                {formErrors.confirmPassword && (
                  <p className="text-danger m-0">
                    <small>{formErrors.confirmPassword}</small>
                  </p>
                )}
                <div>
                  <Link
                    onClick={resetPassword}
                    className={Styles.ResendOtpComponent}>
                    Resend OTP ?
                  </Link>
                </div>

                <div className={Styles.loginBtn}>
                  <button
                    type="submit"
                    className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}>
                    <span className={Styles.FormButtonSpan}>Confirm</span>
                  </button>
                </div>
                {apiErrorMsg && (
                  <p className="text-danger m-0">
                    <small>{apiErrorMsg}</small>
                  </p>
                )}
              </form>
            </div>
          </div>
        </AuthLayout>
      )}
      {/* <div className={Styles.MainDiv}>
        <h2 className={Styles.Heading}>Reset Password</h2>
        <p className={Styles.Paragraph}>
          Strong passwords include numbers, letters, and special characters.
        </p>

        <form className={Styles.Form} onSubmit={handleSubmit}>
          <div>
            <div className={Styles.labelContainer}>
              <p className={Styles.label}>Enter OTP</p>
            </div>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span style={{ width: "8px" }}></span>}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus={true}
              inputStyle={Styles.InputComponent}
              skipDefaultStyles={true}
            />
          </div>
          {formErrors.otp && (
            <p className="text-danger m-0">
              <small>{formErrors.otp}</small>
            </p>
          )}
          <div className={Styles.InputField}>
            <InputField
              label="Enter New Password"
              value={formValue.password}
              type="password"
              placeholder="New Password"
              showPassword={showValues.showPassword1}
              onChange={changeHandler}
              handleTogglePassword={() => handleClickShowPassword(1)}
              height="50px"
              name="password"
              maxLength={40}
            />
          </div>
          {formErrors.password && (
            <p className="text-danger m-0">
              <small>{formErrors.password}</small>
            </p>
          )}

          <div className={Styles.InputField}>
            <InputField
              label="Confirm New Password"
              value={formValue.confirmPassword}
              type="password"
              placeholder="Confirm New Password"
              showPassword={showValues.showPassword2}
              onChange={changeHandler}
              handleTogglePassword={() => handleClickShowPassword(2)}
              height="50px"
              name="confirmPassword"
              maxLength={40}
            />
          </div>
          {formErrors.confirmPassword && (
            <p className="text-danger m-0">
              <small>{formErrors.confirmPassword}</small>
            </p>
          )}

          <button
            style={{ marginTop: 40 }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}
            type="submit"
          >
            <span className={Styles.ButtonTextReset}>Reset Password</span>
          </button>
          <div className={Styles.CancelDiv}>
            <Link to="/login" className={Styles.ButtonTextCancel}>
              Cancel
            </Link>
          </div>
          {apiErrorMsg && (
            <p className="text-danger m-0">
              <small>{apiErrorMsg}</small>
            </p>
          )}
        </form>
      </div> */}
    </>
  );
};

export default ConfirmPasswordComponent;
