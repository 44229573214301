import { useState, useEffect } from "react";
import Styles from "./searchOrders.module.css";
import SubmitModelComponent from "./SubmitModelComponent";
import ButtonStyle from "../Common/Button.module.css";
import { distrubtorOTPAPI, getSearchOrder } from "../../service/api";
import InputField from "../Common/InputField";
import CurrencyFormat from "react-currency-format";
import SearchIcon from "../../Assets/Images/goldefi/searchIcon.svg";
import SearchIcon2 from "../../Assets/Images/goldefi/searchLoading.svg";
import OrderDetailsComponent from "./OrderDetailsComponent";
import LoaderComponent from "../Common/LoaderComponent";
import RedirectPage from "./RedirectPage";
import notify from "../../utils/notify";
import OTPInput from "react-otp-input";

const SearchOrdersComponent = () => {
  const [email, setEmail] = useState("");
  const [orderId, setOrderId] = useState("");
  const [otp, setOtp] = useState("");
  const [otpModal, setOtpModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [orders, setOrder] = useState([]);
  const [currOrder, setCurrOrder] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [redirectScreen, setRedirectScreen] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);

  const getOrder = async () => {
    setShowLoader(true);
    try {
      if (otp.length !== 4) {
        notify.error("Please enter all 4 Digits");
        setShowLoader(false);

        return;
      }
      const data = {
        order_id: orderId,
        user_email: email,
        otp,
      };
      const res: any = await getSearchOrder(data);
      if (res.status) {
        setOrder(res.order_list);
        setOtpModal(false);
      } else {
        notify.error(res.message ? res.message : "Something went wrong!");
      }
    } catch (error) {
      console.log("eerr", error);
    }
    setShowLoader(false);
  };
  const handleSubmit = (order: any) => {
    setShowModal(true);
    setCurrOrder(order);
  };
  const callOTP = async () => {
    try {
      setOtpModal(false);
      setIsActive(false);
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!email.trim()) {
        notify.error("Please enter Client Email");
        return;
      }
      if (!emailPattern.test(email)) {
        notify.error("Please enter Client Email");
        return;
      }
      if (!orderId.trim()) {
        notify.error("Please enter Order ID");
        return;
      }
      setShowLoader(true);

      const data = {
        user_email: email,
        order_id: orderId,
      };
      const res: any = await distrubtorOTPAPI(data);
      if (res.status) {
        notify.success(res.message);
        setOrder([]);
        setOtp("");
        setIsActive(true);
        setOtpModal(true);
        setSeconds(60);
      } else {
        setOtpModal(false);
        setIsActive(false);
        notify.error(res.message ? res.message : "Something went wrong!");
      }
    } catch (error) {
      console.log("callOTP err", error);
    }
    setShowLoader(false);
  };
  useEffect(() => {
    let interval: any = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    } else if (seconds === 0) {
      setIsActive(false);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <>
      {redirectScreen ? (
        <RedirectPage order={currOrder} setRedirectScreen={setRedirectScreen} />
      ) : (
        <>
          <div className={Styles.mainDiv}>
            <div className={Styles.searchBar}>
              <div className={Styles.srchtxt}>
                <img className={Styles.srchImg} src={SearchIcon} alt="Search" />
                <h6 className={Styles.txt}>Search for an Order</h6>
              </div>
              <div>
                <InputField
                  label="Enter Email"
                  value={email}
                  type="email"
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  maxLength={40}
                  dark={true}
                />
              </div>
              <div>
                <InputField
                  label="Order ID"
                  value={orderId}
                  type="text"
                  placeholder="Order ID"
                  onChange={(e) => setOrderId(e.target.value)}
                  name="orderId"
                  maxLength={40}
                  dark={true}
                />
              </div>
              <button
                onClick={callOTP}
                className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.btn} me-3 px-2`}>
                Search
              </button>
            </div>
            {otpModal && (
              <>
                <div className={Styles.OTPContainer}>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span style={{ width: "8px" }}></span>}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus={true}
                    inputStyle={Styles.InputComponent}
                    skipDefaultStyles={true}
                    inputType="number"
                    // containerStyle={Styles.}
                  />
                  <button
                    onClick={getOrder}
                    className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.btn} `}>
                    <span className={Styles.FormButtonSpan}>Validate OTP</span>
                  </button>
                </div>
                <div className={Styles.timer}>
                  <span>Resend OTP in : </span>
                  {seconds === 0 ? (
                    <button className={Styles.timerButton} onClick={callOTP}>
                      Resend
                    </button>
                  ) : (
                    <>
                      <span className={Styles.yellowText}>{seconds}</span> sec
                    </>
                  )}
                </div>
              </>
            )}
            {orders.length === 0 ? (
              <>
                <div className={Styles.search}>
                  <img
                    className={Styles.srchImg2}
                    src={SearchIcon2}
                    alt="Search"
                  />
                </div>
              </>
            ) : (
              <OrderDetailsComponent
                orders={orders}
                handleSubmit={handleSubmit}
              />
            )}
            {showModal && (
              <SubmitModelComponent
                showModal={showModal}
                setShowModal={setShowModal}
                order={currOrder}
                setRedirectScreen={setRedirectScreen}
              />
            )}
            {showLoader && (
              <LoaderComponent message={"We are looking for your Order"} />
            )}
          </div>
        </>
      )}
    </>
  );
};
export default SearchOrdersComponent;
