/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getQrcode,
  userDetailAPI,
  validateQrCodeAPI,
} from "../../../service/api";
import {
  getAWSSession,
  getAWSToken,
  getUserEmail,
  getUserId,
} from "../../../utils/common";
import Styles from "./QRCodeComponent.module.css";
import { copyText } from "../../../utils/common";
import useAlert from "./../../../utils/hooks/alert";
import OtpInput from "react-otp-input";
import Copy from "../../../Assets/Images/copy.svg";
import { setUserDetails } from "../../../redux/actions";
import ButtonStyles from "../../Common/Button.module.css";
import notify from "../../../utils/notify";

const QrCodeForm = ({ hide, setIsOn }: any) => {
  const dispatch = useDispatch();
  const [qrCode, setQrCode] = useState();
  const { showAlert } = useAlert();
  const [code, setCode] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const validation = () => {
    if (code.length < 6 || code !== "" || code !== null || code !== undefined) {
      return true;
    }
    return false;
  };
  const handleSubmitVerify = async () => {
    const validate = validation();
    if (validate) {
      const data = {
        aws_sso_token: getAWSToken(),
        qr_code: code,
        aws_sso_session: getAWSSession(),
        user_name: getUserEmail(),
        multi_tsp_user_id: getUserId(),
      };
      const res: any = await validateQrCodeAPI(data);
      if (res.status) {
        // localStorage.setItem("otp_check", true);
        // showAlert("SUCCESS", "Success", res.message);
        notify.success(res.message);
        setIsOn(true);
        hide();
        const resp = await userDetailAPI();
        dispatch(setUserDetails(resp));
      } else {
        if (code === "") {
          setErrorMsg(true);
        } else {
          // showAlert("ERROR", "Error", res.message);
          notify.error(res.message);
        }
      }
    }
  };

  const [values, setValues] = useState({
    secretKey: "",
  });
  const handleChange = (code: React.SetStateAction<string>) => setCode(code);
  const getQRCode = async () => {
    const data = {
      aws_sso_token: getAWSToken(),
    };
    const res: any = await getQrcode(data);
    if (res.success) {
      const base64 = res.qr_code;
      const imgs = base64.split("'");
      setQrCode(imgs[1]);
      setValues((preValues: any) => ({
        ...preValues,
        secretKey: res.qr_secret,
      }));
    } else {
      hide();
      notify.error(res.message);
      // showAlert("ERROR", "Error", res.message);
    }
  };

  useEffect(() => {
    getQRCode();
  }, []);
  return (
    <div className={"d-flex mt-5 justify-content-between " + Styles.mfaBlock}>
      {" "}
      {qrCode && (
        <>
          <div className={Styles.left}>
            <div className="mb-4">
              <p className={Styles.p1}>STEP 1</p>
              <p className={Styles.p2}>Scan the code below</p>
            </div>
            <div className="text-center">
              <img
                style={{ height: "150px", width: "150px" }}
                src={`data:image/png;base64,${qrCode}`}
              />
            </div>
            <br />
            <span className={Styles.p1}>Secret Key</span> {}
            <div className="input-group">
              <input
                className="form-control bg-gray"
                id="inputAddress"
                disabled
                value={values.secretKey}
              />
              <div
                className={
                  Styles.key +
                  " input-group-text bg-gr cursor-pointer copy-icon"
                }
                onClick={() => {
                  copyText(values.secretKey);
                  notify.success("Secret Key Copied");
                  // showAlert("SUCCESS", "Success", "Secret Key Copied");
                }}>
                <img src={Copy} alt="copy" />
              </div>
            </div>
          </div>
          <div className={Styles.right}>
            <div className="mb-4">
              <p className={Styles.p1}>STEP 2</p>
              <p className={Styles.p2}>Enter the OTP</p>
            </div>
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={6}
              renderSeparator={<span style={{ width: "8px" }}></span>}
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus={true}
              inputStyle={Styles.InputComponent}
              skipDefaultStyles={true}
              inputType="number"
              containerStyle={Styles.OTPContainer}
            />
            {errorMsg && (
              <p className="text-danger">
                <small>Confirmation code must not be empty</small>
              </p>
            )}

            <div className={Styles.loginBtn}>
              <button
                type="submit"
                onClick={handleSubmitVerify}
                className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} ${Styles.btn}`}>
                <span className={Styles.FormButtonSpan}>Validate OTP</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QrCodeForm;
