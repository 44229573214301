import React from "react";
import Styles from "./SwitchInput.module.css";

const Switch = ({ isOn, handleToggle, onColor }: any) => {
  return (
    <>
      <div className={Styles.outerDivFull}>
        <div className={Styles.switchToggle}>
          <input
            checked={isOn}
            type="checkbox"
            id="switch"
            onClick={handleToggle}
          />
          <label htmlFor="switch">Toggle</label>
        </div>
      </div>
    </>
  );
};

export default Switch;
