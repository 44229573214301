import Styles from "./loader.module.css";
import loaderIcon from "../../../Assets/Images/goldefi/loaderIcon.svg";

const LoaderComponent = ({ message }: any) => {
  return (
    <div className={Styles.container}>
      <img src={loaderIcon} alt="loader" className={Styles.rotate} />
      <div className={Styles.text1}>Please wait</div>
      <div className={Styles.text2}>{message}</div>
    </div>
  );
};

export default LoaderComponent;
