import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Styles from "./SubmitModelComponent.module.css";
import ButtonStyle from "../../Common/Button.module.css";
import InputField from "../../Common/InputField";
import { submitPayment } from "../../../service/api";
import { _userSign } from "../../../service/metamask_service";
import notify from "../../../utils/notify";
import moment from "moment";
import { userSign } from "../../../service/thirdweb_service";

interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  order: any;
  setRedirectScreen: (value: boolean) => void;
}

const SubmitModelComponent = ({
  showModal,
  setShowModal,
  order,
  setRedirectScreen,
}: Props) => {
  const [amount, setAmount] = useState(0);
  const [orderDate, setOrderDate] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  console.log("order", order);
  const valid = (amt: number) => {
    if (amt <= 0) return "Enter amount greater than 0.";
    if (amt > order.amount)
      return "Amount should be less than or equal to order Amount";
    if (amt.toString()?.split(".")[1]?.length > 2)
      return "Accepts amount with only 2 decimal place.";
    return "valid";
  };
  const handleChange = (key: string, value: any) => {
    if (key === "date") {
      const date = new Date(value);
      const currDate = new Date();
      const diff =
        new Date(currDate).getFullYear() - new Date(date).getFullYear();
      if (date >= currDate) {
        notify.error("Future Dates not allowed.");
      } else if (diff >= 100) {
        notify.error("Dates greater than 100 years are not allowed.");
      } else {
        setOrderDate(value);
      }
    } else if (key === "amount") {
      const isValid = valid(value);
      if (isValid === "valid") setAmount(value);
      else notify.error(isValid);
    }
  };

  const handlePayment = async () => {
    if (orderDate === "") {
      notify.error("Please select order date");
      return;
    }
    setShowLoader(true);
    try {
      setDisableBtn(true);
      // Set the default network before following function
      const sign = await userSign(
        order.amount,
        moment(orderDate).format("DD/MM/YYYY"),
        order
      );
      if (sign.error || sign.error === "ACTION_REJECTED") throw sign;
      const data = {
        amount: order.amount,
        digital_sign: sign.result,
        order_id: order.order_id,
      };
      const response: any = await submitPayment(data);
      setShowLoader(false);
      if (response.status) {
        notify.success(response.message);
        setRedirectScreen(true);
        setShowModal(false);
      } else {
        notify.error(response.message);
      }
      setDisableBtn(false);
    } catch (error) {
      console.log("err", error);
      setDisableBtn(false);
      setShowLoader(false);
    }
  };

  return (
    <Modal
      // size="lg"
      centered
      show={showModal}
      onHide={() => setShowModal(false)}>
      <Modal.Body className="p-5">
        <div className={Styles.header}>Confirm Amount</div>
        <div className={Styles.subHeader}>
          Please input the amount received from the customer.
        </div>
        <div className={Styles.body}>
          <InputField
            label="Order Date"
            value={orderDate}
            type="date"
            placeholder="Date"
            dateValidation={false}
            onChange={(e) => handleChange("date", e.target.value)}
            name="orderDate"
            min={order.order_date}
          />
          <InputField
            label="Amount Payable"
            value={order.amount}
            type="number"
            disabled={true}
            placeholder="Amount Payable"
            onChange={(e) => handleChange("amount", Number(e.target.value))}
            name="amount"
          />
        </div>
        <div className={Styles.btn}>
          <button
            onClick={handlePayment}
            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} me-3 px-2`}>
            Confirm
          </button>
        </div>
      </Modal.Body>
      {showLoader && (
        <div className="Loader">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
    </Modal>
  );
};

export default SubmitModelComponent;
