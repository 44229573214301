import React from "react";
import BuyToken from "../Components/BuyToken";
import UserLayout from "../Layouts/UserLayout/UserLayout";

const BuyTokens = () => {
  return (
    <UserLayout title="Buy Token" showPaddding={false}>
      <BuyToken />
    </UserLayout>
  );
};

export default BuyTokens;
