import { useEffect, useState } from "react";
import Styles from "./selectComponent.module.css";
import Dropdown from "react-bootstrap/Dropdown";

const SelectComponent = ({
  disabled,
  onChange,
  list,
  showImg = false,
  dark = false,
  disableAutoSelect = false,
}: any) => {
  const [label, setLabel] = useState("Select");

  const handleChange = (item: any) => {
    onChange(item.value);
    setLabel(item.label);
  };

  useEffect(() => {
    if (!disableAutoSelect) {
      if (list.length > 0) {
        setLabel(list[0].label);
      }
    }
  }, []);

  return (
    <Dropdown>
      <Dropdown.Toggle
        disabled={disabled}
        bsPrefix={Styles.Toggle + " " + (dark ? "" : Styles.ToggleLight)}>
        {/* <Dropdown.Item bsPrefix={Styles.Item}> */}
        {showImg && (
          <img
            className={Styles.img}
            src="https://pngimg.com/uploads/bitcoin/bitcoin_PNG48.png"
            width={"45px"}
          />
        )}
        {label}
        {/* </Dropdown.Item> */}
      </Dropdown.Toggle>

      <Dropdown.Menu className={Styles.Menu}>
        {list.map((item: any) => (
          <Dropdown.Item
            key={item.value}
            bsPrefix={Styles.Item}
            onClick={() => handleChange(item)}>
            {showImg && (
              <img
                className={Styles.img}
                src="https://pngimg.com/uploads/bitcoin/bitcoin_PNG48.png"
                width={"45px"}
              />
            )}
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectComponent;
