import React, { Suspense } from "react";
import Layout from "../Layouts/Layout";
// import LoginComponent from "../Components/LoginComponent";
import SuspendLoader from "../Components/Common/SuspendLoader";
const LoginComponent = React.lazy(() => import("../Components/LoginComponent"));

const Login = () => {
  return (
    <Suspense fallback={<SuspendLoader />}>
      <LoginComponent />
      {/* <Layout title="Login">
      </Layout> */}
    </Suspense>
  );
};

export default Login;
