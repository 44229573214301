import React, { useState } from "react";
import Styles from "./page404.module.css";
import { Row, Col } from "react-bootstrap";
import BannerImage from "../../Assets/Images/page404-banner.png";
const Page404Component = () => {
  return (
    <div className={Styles.bannerContainer}>
      <Col className={Styles.col1} md={7}>
        <Row className={Styles.header1}>404</Row>
        <Row className={Styles.header2}>Page not found</Row>
        <br />
        <Row className={Styles.subHeader1}>
          The page you are looking for doesn’t exist or another error occurred.{" "}
        </Row>
      </Col>
    </div>
  );
};
export default Page404Component;
