import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import metamaskService from "../metamask";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsMetamaskInstalled,
  setUserDetails,
  setUserLoginStatus,
} from "../../redux/actions";
import { getAccessToken, getKYCStatus } from "../common";
import { userDetailAPI, userGetKYCAPI } from "../../service/api";
import { setKycReason, setKycStatus } from "../../redux/actions/user-action";
const LoginRoute = () => {
  const dispatch = useDispatch();
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userDetails } = useSelector((state: any) => state.userReducer);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accessToken = getAccessToken();
        if (accessToken) {
          dispatch(setUserLoginStatus(true));
          setIsAuthenticate(true);
          setIsLoading(false);
          if (
            Object.keys(userDetails).length === 0 ||
            userDetails.message == "Login Successfully!"
          ) {
            const res = await userDetailAPI();
            dispatch(setUserDetails(res));
          }
          if (
            !userDetails.kycStatus ||
            userDetails.kycStatus == null ||
            userDetails.kycStatus == undefined
          ) {
            const response: any = await userGetKYCAPI();
            dispatch(setKycStatus(getKYCStatus(response.isUserKYCDone)));
            dispatch(setKycReason(response.reason ? response.reason : ""));
          }
        } else {
          setIsAuthenticate(false);
          dispatch(setUserLoginStatus(false));

          setIsLoading(false);
        }
      } catch (error) {
        console.log("LoginRoute : ", error);
      }
    };

    fetchData();
  }, []);

  return isAuthenticate ? <Navigate to="/my-profile" /> : <Outlet />;
};
export default LoginRoute;
