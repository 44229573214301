import React, { useEffect, useState } from "react";
import Styles from "./LayoutAuthPages.module.css";
import group from "../../../Assets/Images/goldefi/Group.png";
import logo from "../../../Assets/Images/goldefi/goldefi-logo.svg";
import { Link } from "react-router-dom";
import Loader from "../Loader";

const AuthLayout = ({
  loader = false,
  type = "",
  children,
  title = "GolDefi",
}: any) => {
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    window.scrollY < 40 ? setScroll(false) : setScroll(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.title = title;
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className={Styles.MainDiv + " grid row"}>
        <header
          className={`${Styles.authHeader} ${scroll ? Styles.onScroll : ""}`}
        >
          <div className="">
            <Link to="/">
              <img className={Styles.bannerImg} src={logo} alt="banner" />
            </Link>
          </div>
          {type !== "distributor" && (
            <div className={Styles.headerText}>
              {type === "signup" ? "Already" : "Don’t"} have an account?
              <Link
                to={type === "signup" ? "/login" : "/signup"}
                className={Styles.headerSubText}
              >
                {" "}
                {type === "signup" ? "LogIn" : "Sign Up!"}
              </Link>
            </div>
          )}
        </header>

        <div className="col-12 col-sm-6 p-0">
          <img className={Styles.bannerImg} src={group} style={{ alignSelf: "left" }} alt="banner" />
        </div>

        <div className={Styles.col2 + " col-12 col-sm-6"}>{children}</div>

        <footer className={Styles.authFooter}>
          <div className={Styles.footerText}>
            © copyright 2024 by GolDeFi.com
          </div>
        </footer>
      </div>
      {loader && <Loader />}
    </>
  );
};

export default AuthLayout;
