/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import GreetingComponent from "./GreetingComponent";
import OrderComponent from "./OrderComponent";
import Styles from "./dashboard.module.css";
import InvestmentComponent from "./InvestmentCompoent";
import GraphComponent from "./GraphComponent";
import TokenComponent from "./TokenComponent";
import MoneyComponent from "./MoneyComponent";
import BuyMoreComponent from "./BuyMoreComponent";
import { useDispatch, useSelector } from "react-redux";
import { setOrderList, setDashboardData } from "../../redux/actions";
import { getDashboardData, getOrderList } from "../../service/api";
import ContactUsComponent from "./ContactUsComponent";

const DashBoardBody = () => {
  const [imageCounter, setImageCounter] = useState(0);

  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dashDataLoaded, setDashDataLoaded] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const { orderList } = useSelector((state: any) => state.orderReducer);

  const getMyOrderList = async () => {
    const res: any = await getOrderList(pageNo);
    if (res.status) {
      if (res?.data_obj) {
        const list = res.data_obj;
        list.sort(function (a: any, b: any) {
          var c: any = new Date(a.date);
          var d: any = new Date(b.date);
          return d - c;
        });

        dispatch(setOrderList(list));
        setDataLoaded(true);
      }
    }
  };
  const getDashboardStats = async () => {
    try {
      const res: any = await getDashboardData();
      if (res.status) {
        const data = {
          orders_transactions: res?.orders_transactions,
          total_money_invested: res?.total_money_invested,
          current_value_of_tokens_purchased:
            res?.current_value_of_tokens_purchased,
          banner_data: res?.banner_images,
          total_tokens_purchased: res?.total_tokens_purchased,
          current_token_value: res?.current_token_value,
        };
        dispatch(setDashboardData(data));
        setDashDataLoaded(true);
      }
    } catch (error) {
      console.log("eerr", error);
    }
  };
  useEffect(() => {
    if (!dataLoaded) {
      getMyOrderList();
    }
    if (!dashDataLoaded) {
      getDashboardStats();
    }
  }, []);

  return (
    <div className={Styles.dashboardBody}>
      <div className=" px-5 py-4 ">
        <div className="row">
          <div className="col-12">
            <GreetingComponent />
          </div>
        </div>
        <div className="cointainer  mt-3">
          <div className="row">
            <div className="p-4 col-md-4 col-sm-6 col-6">
              <MoneyComponent />
            </div>
            <div className="p-4 col-md-4 col-sm-6 col-6">
              <TokenComponent />
            </div>
            <div className="p-4 col-md-4 col-sm-12 col-xs-12">
              <BuyMoreComponent />
            </div>
          </div>
        </div>
        <div className="cointainer">
          <div className="row  g-4 g-lg-3   ">
            <div className="col-md-6 col-sm-12">
              <GraphComponent />
            </div>
            <div className={" col-md-6 col-sm-12" + Styles.investmentDiv}>
              <InvestmentComponent />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <OrderComponent data={orderList} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashBoardBody;
