import React, { useEffect } from "react";
import Layout from "../Layouts/Layout";

import HomeComponent from "../Components/HomeComponent";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
  }, []);
  return <Layout title="Home Page">{/* <HomeComponent /> */}</Layout>;
};

export default Home;
