import React from "react";
import Style from "./SuspendLoader.module.css";
import LoaderImg from "../../../Assets/Images/loader.svg";
import { Row } from "react-bootstrap";

const SuspendLoader = ({
  header = "Please wait",
  subHeader = "Website is loading...",
}) => {
  return (
    <>
      <div className={Style.container}>
        <Row>
          <img src={LoaderImg} alt="loaderimg" />
        </Row>
        <Row className="d-flex text-center">
          <div className={Style.header1}>{header}</div>
          <div className={Style.header2}>{subHeader}</div>
        </Row>
      </div>
    </>
  );
};
export default SuspendLoader;
