import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Style from "./redeemRefund.module.css";
import ButtonStyles from "../../../Components/Common/Button.module.css";
import notify from "../../../utils/notify";
import metamask from "../../../utils/metamask";

const RedeemRefundModal = ({
  show,
  onHide,
  redeemToken,
  tokenCount,
  redeemptionDetail,
  getFees,
  totalAmount,
}: any) => {
  return (
    <Modal centered={true} size="lg" show={show} onHide={onHide}>
      <Modal.Body className="p-5">
        <Row>
          <Col>
            <div className={Style.header}>Redemption Details</div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <div className={Style.subheader}>
              You are going to redeem your gold
              <br />
              Amount of Troy oz to be Redeemed: <b>{tokenCount} Troy Oz</b>
              <br />
              {tokenCount} Troy Oz in GDI Token :
              <b>
                {" "}
                {tokenCount *
                  JSON.parse(redeemptionDetail.conversion).toFixed(2)}
                {" GDI"}
              </b>
              <br />
              Platform Fees (
              {redeemptionDetail.active == "Fixed" ? "Fixed" : `${getFees()}%`})
              :
              <b>
                {" "}
                {getFees()}
                {" GDI"}
                {/* {redeemptionDetail.active == "Fixed" ? "Fixed" : "%"} */}
              </b>
              <br />
              {/* Platform Fees in GDI Token:
              <b>
                {" "}
                {}{" "}
                {redeemptionDetail.active == "Fixed"
                  ? getFees()
                  : JSON.parse(tokenCount) *
                    JSON.parse(redeemptionDetail.conversion).toFixed(2) *
                    (getFees() / 100)}{" "}
              </b>
              <br /> */}
              Non-Refundable Fees:{" "}
              <b>
                {redeemptionDetail.redemption_processing_fee} {" GDI"}
              </b>
              {/* (Includes shipping, insurance, etc.) */}
              <br />
              {/* Non-Refundable Fees in GDI Tokens:{" "}
              <b>
                {" "}
                {JSON.parse(tokenCount) *
                  JSON.parse(redeemptionDetail.conversion).toFixed(2) *
                  (JSON.parse(
                    redeemptionDetail.redemption_processing_fee
                  ).toFixed(2) /
                    100)}{" "}
              </b> */}
              <br />
              Total GDI to be paid (Redemption amount + Platform Fees +
              Non-Refundable Fees in GDI tokens):{" "}
              <b>
                {totalAmount()} {" GDI"}
              </b>
              <br />
              <br />
              To proceed the redemption request, you will need to pay the
              Non-Refundable Fees by accepting. If you choose to decline, your
              redemption request will be canceled.
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={3} md={3} sm={6} xs={6}>
            <button
              className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeWhiteBackground} me-3 `}
              onClick={onHide}>
              Cancel
            </button>
          </Col>{" "}
          <Col lg={3} md={3} sm={6} xs={6}>
            <button
              className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} `}
              onClick={redeemToken}>
              Accept
            </button>
          </Col>{" "}
        </Row>
      </Modal.Body>
    </Modal>
  );
};
export default RedeemRefundModal;
