import { USER_TYPES } from "../redux-constant-type/index";

const setUserName = (username: any) => {
  return {
    type: USER_TYPES.SET_USERNAME,
    payload: {
      username: username,
    },
  };
};

const setUserWalletAddress = (walletAddress: any) => {
  return {
    type: USER_TYPES.SET_WALLET_ADDRESS,
    payload: {
      walletAddress: walletAddress,
    },
  };
};

const setIsMetamaskInstalled = (status: any) => {
  return {
    type: USER_TYPES.SET_METAMASK_INSTALLED_STATUS,
    payload: {
      status: status,
    },
  };
};

const setIsWalletConnected = (status: any) => {
  return {
    type: USER_TYPES.SET_IS_WALLET_CONNECTED,
    payload: {
      status: status,
    },
  };
};

const setUserLoginStatus = (status: any) => {
  return {
    type: USER_TYPES.SET_IS_LOGGED_IN,
    payload: {
      status: status,
    },
  };
};

const setUserDetails = (values: any) => {
  return {
    type: USER_TYPES.SET_USER_DETAILS,
    payload: {
      userDetails: values,
    },
  };
};
const setUserLogout = () => {
  return {
    type: USER_TYPES.SET_USER_LOGOUT,
  };
};
const setKycStatus = (status: string) => {
  return {
    type: USER_TYPES.SET_KYC_STATUS,
    payload: status,
  };
};
const setKycReason = (reason: string) => {
  return {
    type: USER_TYPES.SET_KYC_REASON,
    payload: { kyc_reason: reason },
  };
};
export {
  setUserName,
  setUserWalletAddress,
  setIsMetamaskInstalled,
  setIsWalletConnected,
  setUserLoginStatus,
  setUserDetails,
  setUserLogout,
  setKycStatus,
  setKycReason,
};
