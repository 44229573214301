import { REDEEM_TYPES } from "../redux-constant-type";

const setRedeemList = (values: any, order: any) => {
  return {
    type: REDEEM_TYPES.SET_REDEEM_LIST,
    payload: {
      redeemList: values,
      redeemOrder: order,
    },
  };
};

export { setRedeemList };
