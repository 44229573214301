// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOtpType, setUserName } from "../../redux/actions";
import { forgotPasswordAPI } from "../../service/api";
import InputField from "../../Components/Common/InputField";
import Styles from "./forgotPassword.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";
import AuthLayout from "../../Components/Common/LayoutAuthPages";
import notify from "../../utils/notify";
import { maskEmail } from "../../utils/common";
interface DataInterface {
  email: string;
}

const ForgotPasswordComponent = () => {
  const { isLoggedIn } = useSelector((state: any) => state.userReducer);
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  // for page navigation purpose
  const navigate = useNavigate();

  // for storing the data in the redux
  const dispatch = useDispatch();

  const [user, setUser] = useState<DataInterface>({
    email: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const [isSubmit, setIsSubmit] = useState<Boolean>(false);

  const [apiErrorMsg, setApiErrorMsg] = useState<String>("");

  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const validateForm = (values) => {
    const errors = {};

    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!regexEmail.test(values.email.trim())) {
      errors.email = "Invalid email format.";
    }
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateForm(user));

    setIsSubmit(true);
  };

  const forgotPasswordHandler = async () => {
    const data = {
      username: user.email.trim(),
    };
    try {
      const response = await forgotPasswordAPI(data);

      if (response.status) {
        // dispatch(setUserName(user.email));
        localStorage.setItem("username", user.email);
        notify.success(
          `A temporary password has been sent to  ${maskEmail(user.email)}`
        );
        // setUser({
        //   email: "",
        // });
        navigate("/confirm-password");
      } else {
        notify.error(response.message);
        // setApiErrorMsg(response.message);
        setUser({
          email: "",
        });
        return;
      }
    } catch (error) {
      console.error("An error occurred during Forgot Password:", error);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      forgotPasswordHandler();
    }
  }, [formErrors]);

  // useEffect(() => {
  //   try {
  //     const isLoggedIn = localStorage.getItem("loggedIn");
  //     if (isLoggedIn == "true") {
  //       navigate("/");
  //       return;
  //     }
  //     document.title = "Forgot Password";
  //   } catch {}
  // }, []);

  useEffect(() => {
    if (screenSize !== 0) {
      screenSize >= 768 ? setIsMobileScreen(false) : setIsMobileScreen(true);
    }
  }, [screenSize]);
  return (
    <>
      <AuthLayout>
        <div className={Styles.loginContainer}>
          <div className={Styles.thirdLogin}>
            <div className={Styles.heading}>Recover Password</div>
            <div className={Styles.subHeading}>
              Please enter the email address you signed up with and we will send
              you instructions to reset your password.
            </div>
          </div>
          <div className={Styles.loginform}>
            <form className={Styles.Form} onSubmit={handleSubmit}>
              <InputField
                // label="Email"
                value={user.email}
                type="email"
                placeholder="Enter Email Address"
                onChange={changeHandler}
                height="50px"
                name="email"
                maxLength={40}
                dark={true}
              />
              {formErrors.email && (
                <p className="text-danger">
                  <small>{formErrors.email}</small>
                </p>
              )}

              <div className={Styles.FormForgotPassword}>
                <Link to="/login" className={Styles.forgotPassword}>
                  Back to log in
                </Link>
              </div>

              <div className={Styles.loginBtn}>
                <button
                  type="submit"
                  className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}>
                  <span className={Styles.FormButtonSpan}>Confirm</span>
                </button>
              </div>
              {apiErrorMsg && (
                <p className="text-danger">
                  <small>{apiErrorMsg}</small>
                </p>
              )}
            </form>
          </div>
        </div>
      </AuthLayout>
      {/* <div className={Styles.MainDiv}>
        <h2 className={Styles.Heading}>Forgot Password</h2>
        <p className={Styles.Paragraph}>
          Please enter the email address you signed up with and we will send
          you instructions to reset your password.
        </p>

        <form className={Styles.Form} onSubmit={handleSubmit}>
          <div>
            <InputField
              label="Enter Email Address"
              value={user.email}
              type="email"
              placeholder="Email"
              onChange={changeHandler}
              height="50px"
              name="email"
              maxLength={30}
            />
          </div>
          {formErrors.email && (
            <p className="text-danger">
              <small>{formErrors.email}</small>
            </p>
          )}

          <button
            type="submit"
            style={{ marginTop: 40 }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}
          >
            <span className={Styles.FormSubmitButtonText}>Request OTP</span>
          </button>
          {apiErrorMsg && (
            <p className="text-danger">
              <small>{apiErrorMsg}</small>
            </p>
          )}

          <div className={Styles.FormBackToLogin}>
            <Link className={Styles.FormBackToLogin} to="/login">
              Back to Log in
            </Link>
          </div>
        </form>
      </div> */}
    </>
  );
};

export default ForgotPasswordComponent;
