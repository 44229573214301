import React from "react";
import { toast } from "react-hot-toast";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { IoWarning } from "react-icons/io5";
import { MdCancel, MdCheckCircle } from "react-icons/md";

/** Toast notifications. */
const notify = {
  /**
   * Toast to show some information.
   * @param message
   */
  info: (message: string) =>
    toast(message, {
      style: {
        borderRadius: "11px 9px 9px 11px",
      },
      className: "mt-5 bg-white py-0 ps-0 pe-3 text-center fw-bold",
      icon: (
        <div
          className="d-flex align-items-center bg-primary p-3 me-2 text-center cursor-pointer"
          onClick={() => toast.dismiss()}
          style={{
            borderRadius: "9px 0px 0px 9px",
            height: "100%",
          }}>
          <BsFillInfoCircleFill size="20px" className="text-white" />
        </div>
      ),
    }),
  /**
   * Toast to show a success message.
   * @param message
   */
  success: (message: string) =>
    toast(message, {
      style: {
        borderRadius: "11px 9px 9px 11px",
      },
      className: "mt-5 bg-white py-0 ps-0 pe-3 text-center fw-bold",
      icon: (
        <div
          onClick={() => toast.dismiss()}
          className="d-flex align-items-center bg-success p-3 me-2 text-center cursor-pointer"
          style={{
            borderRadius: "9px 0px 0px 9px",
            height: "100%",
          }}>
          <MdCheckCircle size="20px" className="text-white" />
        </div>
      ),
    }),
  /**
   * Toast to show a warning message.
   * @param message
   */
  warning: (message: string) =>
    toast(message, {
      style: {
        borderRadius: "11px 9px 9px 11px",
      },
      className: "mt-5 bg-white py-0 ps-0 pe-3 text-center fw-bold",
      icon: (
        <div
          className="d-flex align-items-center bg-warning p-3 me-2 text-center cursor-pointer"
          onClick={() => toast.dismiss()}
          style={{
            borderRadius: "9px 0px 0px 9px",
            height: "100%",
          }}>
          <IoWarning size="20px" className="text-white" />
        </div>
      ),
    }),
  /**
   * Toast to show some error.
   * @param message
   */
  error: (message: string) =>
    toast(message, {
      style: {
        borderRadius: "11px 9px 9px 11px",
      },
      className: "mt-5 bg-white py-0 ps-0 pe-3 text-center fw-bold",
      icon: (
        <div
          className="d-flex align-items-center bg-danger p-3 me-2 text-center cursor-pointer"
          onClick={() => toast.dismiss()}
          style={{
            borderRadius: "9px 0px 0px 9px",
            height: "100%",
          }}>
          <MdCancel size="20px" className="text-white" />
        </div>
      ),
    }),
};

export default notify;
