import React, { useState, memo } from "react";
import Styles from "./pagination.module.css";

interface Props {
  totalPages: number;
  callAPI: any;
  setPageNo: any;
  pageNo: number;
}

const Pagination = ({ totalPages, callAPI, setPageNo, pageNo }: Props) => {
  const [currentPage, setCurrentPage] = useState(pageNo);

  const handleAPI = () => {
    const timer = setTimeout(() => {
      callAPI();
    }, 1000);
  };
  const generatePagination = () => {
    const paginationItems = [];

    // Previous button
    paginationItems.push(
      <li
        key="previous"
        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
        <a
          className="page-link"
          href="#"
          onClick={() => {
            setCurrentPage(currentPage - 1);
            setPageNo(currentPage - 1);
            callAPI(currentPage - 1);
          }}>
          Previous
        </a>
      </li>
    );

    // Ellipsis before current page
    if (currentPage > 3) {
      paginationItems.push(
        <li key="ellipsis-before" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Pages around the current page
    for (
      let i = Math.max(1, currentPage - 2);
      i <= Math.min(currentPage + 2, totalPages);
      i++
    ) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}>
          <a
            className="page-link"
            href="#"
            onClick={() => {
              setCurrentPage(i);
              setPageNo(i);
              callAPI(i);
            }}>
            {i}
          </a>
        </li>
      );
    }

    // Ellipsis after current page
    if (currentPage < totalPages - 2) {
      paginationItems.push(
        <li key="ellipsis-after" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Next button
    paginationItems.push(
      <li
        key="next"
        className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
        <a
          className="page-link"
          href="#"
          onClick={() => {
            setCurrentPage(currentPage + 1);
            setPageNo(currentPage + 1);
            callAPI(currentPage + 1);
          }}>
          Next
        </a>
      </li>
    );

    return paginationItems;
  };

  return (
    <nav className={Styles.pagination} aria-label="Page navigation example">
      <ul className="pagination">{generatePagination()}</ul>
    </nav>
  );
};

export default memo(Pagination);
