import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";

import BuyToken from "../Pages/BuyToken";
import MyOrders from "../Pages/MyOrders";
import MyColllection from "../Pages/MyCollection";
import ResellRequest from "../Pages/ResellRequest";
import WithdrawRequest from "../Pages/WithdrawRequest";
import RedemptionRequest from "../Pages/RedemptionRequestList";
import Signup from "../Pages/Signup";
import Login from "../Pages/Login";
import DistributorLogin from "../Pages/DistributorLogin";
import ForgotPassword from "../Pages/ForgotPassword";
import CheckEmailComponent from "../Pages/CheckEmail";
import WalletConnect from "../Pages/walletConnect";
import VerifyOtp from "../Pages/VerifyOtp";
import PrivateRoute from "../utils/hooks/private-route";
import PublicRoute from "../utils/hooks/public-route";
import Page404 from "../Pages/Page404";
import Faq from "../Pages/FAQ";
import About from "../Pages/About";
import ContactUs from "../Pages/ContactUs";
import PrivatePolicy from "../Pages/PrivatePolicy";
import ConfirmPassword from "../Pages/ConfirmPassword";
import PasswordChangedSuccess from "../Pages/PasswordChangedSuccess";
import WhyGraphite from "../Pages/WhyGraphite";
import Dashboard from "../Pages/Dashboard";
import MyProfile from "../Pages/MyProfile";
import Purchase from "../Pages/Purchase";
import Support from "../Pages/Support";
import CustomToast from "../utils/customToast";
import ChangePassword from "../Pages/ChangePassword";
import PurchaseToken from "../Pages/PurchaseToken";
import LoginRoute from "../utils/hooks/login-route";
import SecondarySale from "../Pages/SecondarySale";
import SearchOrders from "../Pages/searchOrders";
import SearchDistrubuter from "../Pages/searchDistrubuter";
import MetaTransaction from "../Pages/MetaTransaction";
import ViewPaymentPage from "../Pages/ViewPayment";
import BuyTokens from "../Pages/BuyTokens";
import RedeemTokenList from "../Pages/RedemptionRequestList";
import RedeemToken from "../Pages/RedeemToken";
import DistributorProfile from "../Pages/DistributorProfile";
import SubmitOtp from "../Pages/SubmitOtp";
// import ViewPayments from "../Pages/ViewPayments";
import DistributerPayment from "../Pages/DistributerPayment";
import DistributerSupport from "../Pages/DistributerSupport";
const RouteComponent = () => {
  return (
    <Router>
      <CustomToast />
      <Suspense>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route element={<LoginRoute />}>
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/verify-otp" element={<VerifyOtp />} />
              <Route path="/submit-otp" element={<SubmitOtp />} />
              <Route path="/distributor-login" element={<DistributorLogin />} />
              <Route
                path="/distributor_verify"
                element={<Signup role="distributor" />}
              />
              <Route path="verify-otp" element={<VerifyOtp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/check-email" element={<CheckEmailComponent />} />
              <Route path="/confirm-password" element={<ConfirmPassword />} />

              <Route
                path="/password-changed-success"
                element={<PasswordChangedSuccess />}
              />
            </Route>

            <Route path="/" element={<Home />} />
            <Route path="/payment-complete" element={<Home />} />
            <Route path="/walletConnect" element={<WalletConnect />} />
            <Route path="/buy-token" element={<BuyToken />} />
            <Route path="/withdraw-request" element={<WithdrawRequest />} />
            <Route path="/redemption-request" element={<RedemptionRequest />} />
            <Route path="/faq" element={<Faq />} />
            {/* <Route path="/about" element={<About />} /> */}
            {/* <Route path="/why-graphite" element={<WhyGraphite />} /> */}
            {/* <Route path="/contact-us" element={<ContactUs />} /> */}
            <Route path="/privacy-policy" element={<PrivatePolicy />} />
          </Route>

          {/* All Private Route */}
          <Route element={<PrivateRoute />}>
            <Route path="/purchase" element={<Purchase />} />
            <Route path="/buy-tokens" element={<BuyTokens />} />
            <Route path="/redeem-list" element={<RedeemTokenList />} />
            <Route path="/redeem-tokens" element={<RedeemToken />} />
            <Route path="/my-orders" element={<MyOrders />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/my-collection" element={<MyColllection />} />
            <Route path="/resell-request" element={<ResellRequest />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/purchase-token" element={<PurchaseToken />} />
            <Route path="/secondary-sale" element={<SecondarySale />} />
            <Route path="/search-distributor" element={<SearchDistrubuter />} />
            <Route path="/meta-transaction" element={<MetaTransaction />} />

            <Route path="/my-profile" element={<MyProfile role={"user"} />} />
            <Route
              path="/distributor-profile"
              element={<DistributorProfile role={"distributor"} />}
            />
            {/* <Route path="/view-payment" element={<ViewPaymentPage />} /> */}
            <Route
              path="/search-orders"
              element={<SearchOrders role={"distributor"} />}
            />
            <Route
              path="/payment-recipts"
              element={<DistributerPayment role={"distributor"} />}
            />
            <Route
              path="/distributer-support"
              element={<DistributerSupport role={"distributor"} />}
            />
            <Route path="/support" element={<Support />} />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RouteComponent;
