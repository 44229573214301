
import { PAYMENT_TYPES } from "../redux-constant-type";

const setPaymentList = (values: any) => {
  return {
    type: PAYMENT_TYPES.SET_PAYMENT_LIST,
    payload: {
      paymentList: values,
    },
  };
};

export {
    setPaymentList
};
