import React, { Suspense } from "react";
import SuspendLoader from "../Components/Common/SuspendLoader";
const SignupComponent = React.lazy(
  () => import("../Components/SignupComponent")
);

type Props = {
  role?: string;
};

const Signup = ({ role = "user" }: Props) => {
  return (
    <Suspense fallback={<SuspendLoader />}>
      <SignupComponent role={role} />
    </Suspense>
  );
};

export default Signup;
