import { MINE_TYPES } from "../redux-constant-type";

const setMineList = (values: any) => {
  return {
    type: MINE_TYPES.SET_MINE_DATA,
    payload: {
      mineList: values,
    },
  };
};

const setMineResellList = (values: any) => {
    return {
      type: MINE_TYPES.SET_MINE_RESELL_DATA,
      payload: {
        mineList: values,
      },
    };
  };

export {
    setMineList, setMineResellList
};
