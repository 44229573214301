import Modal from "react-bootstrap/Modal";
import ButtonStyles from "../../../Common/Button.module.css";
import { IoWarningSharp } from "react-icons/io5";
const AddressWarningModalComponent = ({
  show,
  selectedBlackListed,
  selectedGreyListed,
  onHide,
  handleConfirm,
}: any) => {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      centered={true}
      size="lg"
      show={show}
      onHide={onHide}>
      <Modal.Body>
        <p className="text-center my-3 regular-font">
          <div className="d-flex justify-content-center text-center mb-3">
            <IoWarningSharp size={40} color="#e8c056" />
            {selectedBlackListed && (
              <div
                style={{ margin: "auto 0", fontWeight: 600, fontSize: "20px" }}>
                Notice of KYC Status Revocation
              </div>
            )}
          </div>
          {/* {selectedBlackListed &&
            `You have selected a Blacklisted country in your new address. Updating your address will result in your KYC being disabled so you need to re-verify your account`}
           */}
          {selectedBlackListed && (
            <>
              <div>
                We regret to inform you that due to international regulations or
                sanctions, GolDeFi does not support services in your country.
                Consequently, we must revoke your KYC (Know Your Customer)
                status on our platform. This revocation means you will no longer
                be able to purchase or redeem GDI directly from us.
              </div>
              <br />
              <div>
                {" "}
                If you believe this decision is an error, please contact our
                Compliance department at{" "}
                <a
                  href="mailto:compliance@goldefi.io"
                  target="_blank"
                  style={{ color: "blue" }}>
                  compliance@goldefi.io.
                </a>
              </div>
              <br />
              <div>
                {" "}
                We apologize for any inconvenience this may cause and appreciate
                your understanding.
              </div>
              <br />
            </>
          )}
          {selectedGreyListed &&
            `You have selected a greylisted country in your new address. Updating your address might result in putting your KYC on hold until you upload additional document`}
        </p>

        <div className="d-flex justify-content-center gap-4 mt-4">
          <button
            type="submit"
            onClick={() => {
              onHide();
            }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`}>
            Cancel
          </button>
          <button
            type="submit"
            onClick={() => {
              selectedBlackListed ? handleConfirm() : onHide();
            }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`}>
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddressWarningModalComponent;
