//@ts-nocheck
import React, { useEffect, useState } from "react";
import UserHeader from "../../Components/Common/UserHeader";
import DistributorHeader from "../../Components/Common/DistributorHeader";
import Navigation from "../../Components/Common/Navigation";
import ErrorBoundary from "../../Components/Common/ErrorBoundary";
import Styles from "./UserLayout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setScreenSize } from "../../redux/actions";
import MobileBottomNav from "../../Components/Common/MobileBottomNav";
import bgImg from "../../Assets/Images/goldefi/bgImg.svg";
import { useWalletConnect } from "../../service/thirdweb_service";

const UserLayout = ({
  children,
  title = "GMT",
  role = "",
  showPaddding = true,
}: any) => {
  const dispatch = useDispatch();
  const { screenSize } = useSelector((state: any) => state.layoutReducer);
  useWalletConnect();

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    document.title = title;
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [title]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 426 && window.innerWidth < 769) {
        dispatch(setScreenSize(768));
        setWidth(768);
      } else if (window.innerWidth < 426 || window.innerWidth == 426) {
        dispatch(setScreenSize(425));
        setWidth(425);
      } else {
        dispatch(setScreenSize(1024));
        setWidth(1024);
      }
    };
    if (screenSize == 0) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`${Styles.LayoutContainer} ${showPaddding ? Styles.pageBodyGap : ""
        }`}>
      {role === "distributor" ? (
        <DistributorHeader title={title} />
      ) : (
        <UserHeader title={title} />
      )}
      <ErrorBoundary fallback={<p>Something went wrong</p>}>
        <img src={bgImg} alt="bgImg" className={Styles.bgImg} />
        <div
          className={`${Styles.PageBody} ${showPaddding ? Styles.pageBodyPadding : ""
            }`}>
          {children}
        </div>
      </ErrorBoundary>
      {/* {width < 426 ? (
        <nav
          className={`navbar fixed-bottom navbar-light bg-light d-flex justify-content-around ${Styles.bottomNav}`}>
          <MobileBottomNav role={role} />
        </nav>
      ) : (
        ""
      )} */}
    </div>
  );
};
export default UserLayout;
