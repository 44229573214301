const URLS = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  RELAYER_BASE_URL: process.env.REACT_APP_RELAYER_URL,
  REACT_APP_BASE_IMG_URL: process.env.REACT_APP_BASE_IMG_URL,
  LOGIN_URL: "auth/login",
  DISTRIBUTOR_LOGIN_URL: "auth/distributor-login",
  SIGNUP_URL: "sso/signup",
  FORGOT_PASSWORD_URL: "sso/forget-password",
  VERIFY_OTP_URL: "sso/verify_otp",
  RESEND_OTP_URL: "sso/resend-code",
  CONFIRM_FORGOT_PASSWORD: "sso/confirm-forget-password",
  STRIP_PAYMENT: "create-checkout-session",
  CONTACT_US: "contact-us",
  GET_MINE_ASSET: "asset_request/master",
  BUY_MINE_TOKEN: "buy_mine_token",
  USER_DETAIL_URL: "get/user/detail",
  USER_UPDATE_URL: "user/detail/update",
  RESET_PASSWORD: "set_password",
  GET_KYC_STATUS: "kyc/status",
  UPDATE_TRANSACTION: "order/transaction_verification",
  ORDER_LIST: "order/list",
  REDEEM_LIST: "get_redemption-request",
  PAYMENT_LIST: "list/payment",
  ADMIN_DETAILS: "admin/details",
  COUNTRY_CODES: "country_code",
  GET_DOCUMENT: "get/mine/document/list/download",
  DASH_API: "cms/pages/dashboard",
  FAQ_API: "homepage/faq",
  GET_MY_COLLECTION: "get/user/mycollections",
  GET_MINE_RESELL_COLLECTION: "get/listed_token",
  GET_RESELL_MINE_URL: "list_mine_token",
  GET_WITHDRAW_MINE_URL: "delist/secondary_token",
  REDEEM_MINE: "redemption_request",
  RECOVER_TOKEN: "recovertoken",
  ORDER_BOOK_DETAILS: "get/relisttoken",
  SECONDARY_TRADE_HISTORY: "trade/history",
  PURCHASE_SECONDARY_TOKEN: "buy_secondary_token",
  SEARCH_ORDER: "search/order",
  SUBMIT_PAYMENT: "create/payment",
  VERIFY_QRCODE: "validate/mfa/code",
  GET_QRCODE: "get/mfa/qrcode",
  DISABLE_2FA_CODE: "disable/mfa/code",
  SUMSUB_ACCESS_TOKEN: "auth/sumsub_access_token",
  DISTRIBUTOR_VERIFY_URL: "distributor_verify_backend",
  DISTRIBUTOR_SIGNUP_URL: "sso/distributor_signup",
  REDEEMPTION_PROCESS: "redemption_process",
  REDEEM_REQUEST: "redemption-request",
  BANK_DETAIL: "bank/information",
  BANK_TRANSACTION: "bank/transaction",
  DISTRIBUTOR_OTP: "send/otp",
  PROFESSIONAL_DETAILS: "signup/details",
  MINT_DETAILS: "order/minting",
  COUNTRY_CODE: "country_code",
  UPDATE_EMAIL: "send-otp",
  VERIFY_EMAIL_OTP: "validate/email",
  DISTRIBUTOR_DETAIL: "distributor/details",
  REDEMPTION_REQUEST: "accept_cancel_reedeem_request",
  CREATE_META_TRANSACTION: "meta-txn-create",
  UPDATE_TRANSACTION_HASH: "update/txn/hash",
  RELAYER_CREATE_REDEMPTION: "createRedemption",
  RELAYER_PROCESS_REDEMPTION: "processRedemption",
  RELAYER_SUBSIDIZE_TRANSACTION: "transfer",
  RELAYER_BULKTRANSFER_TRANSACTION: "batchTransfer",
  UPDATE_DIRECT_TRANSACTION_HASH: "direct-txn-hash",
};

export default URLS;
