// @ts-nocheck

import React, { useState, useEffect } from "react";
import Styles from "./ChangePassword.module.css";
import InputField from "../../Common/InputField";
import ButtonStyle from "../../Common/Button.module.css";
import { Modal } from "react-bootstrap";
import { resetPasswordAPI } from "../../../service/api";
import { useSelector } from "react-redux";
import { forgotPasswordAPI } from "../../../service/api";
import notify from "../../../utils/notify";
import Lock from "../../../Assets/Images/goldefi/lock.svg";

interface UserInterface {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  setShowConfirmModal: (value: boolean) => void;
  role: string;
}

const ChangePasswordComponent = ({
  showModal,
  setShowModal,
  setShowConfirmModal,
  role,
}: Props) => {
  const [user, setUser] = useState<UserInterface>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [apiErrorMsg, setApiErrorMsg] = useState<String>("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showValues, setShowValues] = useState({
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
  });
  const [forgotPasswordApiErrorMsg, setForgotPasswordApiErrorMsg] =
    useState<String>("");
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const handleClickShowPassword = (num: number) => {
    if (num == 1) {
      setShowValues({
        ...showValues,
        showPassword1: !showValues.showPassword1,
      });
    } else if (num == 2) {
      setShowValues({
        ...showValues,
        showPassword2: !showValues.showPassword2,
      });
    } else if (num == 3) {
      setShowValues({
        ...showValues,
        showPassword3: !showValues.showPassword3,
      });
    }
  };

  const validateForm = (values: UserInterface) => {
    const errors = {};

    const lengthRegex = /.{8,}/;

    if (!values.currentPassword.trim()) {
      errors.currentPassword = "Current password is required.";
    } else if (!lengthRegex.test(values.currentPassword)) {
      errors.currentPassword =
        "Current password should be at least 8 characters.";
    }

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!values.newPassword.trim()) {
      errors.newPassword = "New password is required.";
    } else if (!lengthRegex.test(values.newPassword.trim())) {
      errors.newPassword = "New password must be at least 8 characters.";
    } else if (!uppercaseRegex.test(values.newPassword.trim())) {
      errors.newPassword =
        "New password must contain at least one uppercase letter.";
    } else if (!lowercaseRegex.test(values.newPassword.trim())) {
      errors.newPassword =
        "New password must contain at least one lowercase letter.";
    } else if (!numberRegex.test(values.newPassword.trim())) {
      errors.newPassword = "New password must contain at least one number.";
    } else if (!specialCharacterRegex.test(values.newPassword.trim())) {
      errors.newPassword =
        "New password must contain at least one special character.";
    } else if (values.currentPassword.trim() === values.newPassword.trim()) {
      errors.newPassword = "Current password and new password can not be same.";
    }

    if (!values.confirmPassword.trim()) {
      errors.confirmPassword = "Confirm password is required.";
    } else if (values.confirmPassword.trim() !== values.newPassword.trim()) {
      errors.confirmPassword =
        "Confirm password and new password should be same.";
    }

    return errors;
  };

  const changePasswordHandler = async () => {
    const useData = {
      old_password: user.currentPassword,
      confirm_password: user.newPassword,
      email: localStorage.getItem("user_email"),
    };
    try {
      // api calling
      const data: any = await resetPasswordAPI(useData);

      if (data.status) {
        notify.success(data.message);
        setShowModal(false);
        setUser({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        return;
      } else {
        setApiErrorMsg(data.message);
        setUser({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        return;
      }
    } catch (error) {
      console.error("An error occurred during Reset:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormErrors(validateForm(user));
    setIsSubmit(true);
  };

  // for handling the input box changes
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const openForgetModal = async () => {
    const username = localStorage.getItem("user_email");
    const data = {
      username: username,
      user_id: null,
      access_token: null,
    };

    const responseData = await forgotPasswordAPI(data);

    if (responseData.status) {
      notify.success(responseData.message);
      setShowModal(false);
      setShowConfirmModal(true);
    } else {
      setForgotPasswordApiErrorMsg(responseData.message);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      changePasswordHandler();
    }
  }, [formErrors]);

  useEffect(() => {
    if (screenSize !== 0) {
      screenSize >= 768 ? setIsMobileScreen(false) : setIsMobileScreen(true);
    }
  }, [screenSize]);
  useEffect(() => {
    setUser({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setFormErrors({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, []);
  return (
    <div className="d-flex justify-content-between">
      <div className={Styles.detailsCol}>
        <div className={Styles.textHeading}>Update Password</div>

        <div className="d-grid gap-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div>
            <InputField
              // label="Current Password"
              value={user.currentPassword}
              type="password"
              placeholder="Current Password"
              onChange={changeHandler}
              height="50px"
              name="currentPassword"
              showPassword={showValues.showPassword1}
              handleTogglePassword={() => handleClickShowPassword(1)}
            />
            {formErrors.currentPassword && (
              <p className="text-danger mb-0">
                <small>{formErrors.currentPassword}</small>
              </p>
            )}
          </div>
          <div>
            <InputField
              // label="Current Password"
              value={user.newPassword}
              type="password"
              placeholder="New Password"
              onChange={changeHandler}
              height="50px"
              name="newPassword"
              showPassword={showValues.showPassword2}
              handleTogglePassword={() => handleClickShowPassword(2)}
            />
            {formErrors.newPassword && (
              <p className="text-danger mb-0">
                <small>{formErrors.newPassword}</small>
              </p>
            )}
          </div>
          <div>
            <InputField
              // label="Current Password"
              value={user.confirmPassword}
              type="password"
              placeholder="Confirm Password"
              onChange={changeHandler}
              height="50px"
              name="confirmPassword"
              showPassword={showValues.showPassword3}
              handleTogglePassword={() => handleClickShowPassword(3)}
            />
            {formErrors.confirmPassword && (
              <p className="text-danger mb-0">
                <small>{formErrors.confirmPassword}</small>
              </p>
            )}
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.updateBtn} px-2 my-4`}>
          Update
        </button>
      </div>
      {role !== "user" && (
        <img className={Styles.mobDNone} src={Lock} alt="password" />
      )}
    </div>
  );
};
export default ChangePasswordComponent;
