import { useDispatch } from "react-redux";
import {
  setCloseAlertData,
  setShowAlertData,
} from "../../redux/actions/alert-action";

const useAlert = () => {
  const dispatch = useDispatch();

  const showAlert = (type, title, message) => {
    const values = {
      alertType: type,
      alertTitle: title,
      alertMessage: message,
    };
    dispatch(setShowAlertData(values));
    setTimeout(() => {
      closeAlert();
    }, [3000]);
  };

  const closeAlert = () => {
    dispatch(setCloseAlertData());
  };

  return {
    showAlert,
    closeAlert,
  };
};

export default useAlert;
