// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { distributorLoginAPI, userDetailAPI } from "../../service/api";
import loginSubmit from "../../utils/login-submit";
import { setUserDetails } from "../../redux/actions";
import InputField from "../Common/InputField";
import Styles from "./distributorLogin.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";
import Form from "react-bootstrap/Form";
import AuthLayout from "../../Components/Common/LayoutAuthPages";
import PasswordInputField from "../Common/PasswordInputField";

interface UserInterface {
  email: string;
  password: string;
}

const DistributorLoginComponent = () => {
  // for page navigation purpose
  const navigate = useNavigate();

  // for storing the data in the redux
  const dispatch = useDispatch();

  const [user, setUser] = useState<UserInterface>({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [apiErrorMsg, setApiErrorMsg] = useState<String>("");
  const [switchState, setSwitchState] = useState(false);

  // for handling the input box changes
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // validating the form errors
  const validateForm = (values: UserInterface) => {
    const errors = {};

    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!regexEmail.test(values.email.trim())) {
      errors.email = "Invalid email format.";
    }

    const lengthRegex = /.{8,}/;

    if (!values.password.trim()) {
      errors.password = "Password is required.";
    } else if (!lengthRegex.test(values.password.trim())) {
      errors.password = "Password must be at least 8 characters.";
    }
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormErrors(validateForm(user));

    setIsSubmit(true);
  };


  const loginHandler = async () => {
    const useData = {
      login: user.email.trim(),
      password: user.password.trim(),
      user_id: null,
      access_token: null,
    };

    try {
      // api calling
      const data: any = await distributorLoginAPI(useData);

      if (data.status) {
        const res = loginSubmit(data, dispatch);
        if(res==='/my-profile'){
          let resp = await userDetailAPI();
          resp.token_expire = data.token_expire;
          // const response: any = await userGetKYCAPI();
          // dispatch(setKycStatus(getKYCStatus(response.isUserKYCDone)));
          dispatch(setUserDetails(resp));
          setUser({
                    email: "",
                    password: "",
                  });
                  navigate("/distributor-profile");
        }
        else{
          navigate(res)
        }

      } else {
        setApiErrorMsg(data.message);
        setUser({
          email: "",
          password: "",
        });
        return;
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("login_page", "distributor");
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      loginHandler();
    }
  }, [formErrors]);

  return (
    <AuthLayout type="distributor">
      <div className={Styles.loginContainer}>
        <div className={Styles.thirdLogin}>
          <div className={Styles.heading}>Welcome Back</div>
          <div className={Styles.subHeading}>
            Login into your Distributor Account
          </div>
        </div>
        {/* <div className={Styles.line}></div> */}

        <div className={Styles.loginform}>
          <form className={Styles.Form} onSubmit={handleSubmit}>
            <InputField
              label="Email"
              value={user.email}
              type="email"
              placeholder="Email"
              onChange={changeHandler}
              // height="50px"
              name="email"
              maxLength={40}
              dark={true}
            />
            {formErrors.email && (
              <p className="text-danger">
                <small>{formErrors.email}</small>
              </p>
            )}

            <PasswordInputField
              label="Password"
              value={user.password}
              type="password"
              placeholder="Password"
              showPassword={showPassword}
              onChange={changeHandler}
              handleTogglePassword={handleClickShowPassword}
              // height="50px"
              name="password"
              maxLength={40}
              dark={true}
            />
            {formErrors.password && (
              <p className="text-danger">
                <small>{formErrors.password}</small>
              </p>
            )}

            <div className={Styles.FormForgotPassword}>
              <Link to="/login" className={Styles.forgotPassword}>
                Login as User
              </Link>
              <Link to="/forgot-password" className={Styles.forgotPassword}>
                Recover Password
              </Link>
            </div>

            <div className={Styles.loginBtn}>
              <button
                type="submit"
                className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}>
                <span className={Styles.FormButtonSpan}>Log in</span>
              </button>
            </div>
            {apiErrorMsg && (
              <p className="text-danger">
                <small>{apiErrorMsg}</small>
              </p>
            )}
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default DistributorLoginComponent;
