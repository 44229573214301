//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { bankDetailAPI, bankTransactionAPI } from "../../../service/api";
import ButtonStyles from "../../Common/Button.module.css";
import Styles from "./TransactionModalComponent.module.css";
import InputField from "../../Common/InputField";
import { useSelector } from "react-redux";
import { copyText, getDateFormat, getShortName } from "../../../utils/common";
import { LuCopy } from "react-icons/lu";
import { FiExternalLink } from "react-icons/fi";
interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  data: any;
}

const TransactionModalComponent = ({
  showModal,
  setShowModal,
  data,
}: Props) => {
  const { userDetails } = useSelector((state: any) => state.userReducer);
  const DesktopOrderTable = () => {
    console.log("data", data, data.length);
    const handleDate = (d) => {
      const date = d?.mint_time
        ? getDateFormat(d?.mint_time.split(" ")[0])
        : "N/A";
      return date;
    };
    return (
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-12 mx-3 px-3 mb-3">
            <div className="d-flex justify-content-between">
              <div className="tableHeading col px-1 fs-6">Order Time</div>
              <div className="tableHeading col px-1 fs-6">Order ID</div>
              <div className="tableHeading col px-1 fs-6">Vault ID</div>
              <div className="tableHeading col px-1 fs-6">Vauld Name</div>
              <div className="tableHeading col px-1 fs-6">Owner</div>
              <div className="tableHeading col px-1 fs-6">Transaction Hash</div>
            </div>
          </div>
          {data &&
            data.length > 0 &&
            data.map((d, indx) => {
              return (
                <div
                  key={`order-+${indx}`}
                  className={"col-md-12 mx-3 tableRowLight py-4 px-3 mt-1"}>
                  <div className="d-flex justify-content-between">
                    <div className=" tableText col px-1 fs-6">
                      {handleDate(d)}
                    </div>
                    <div className="tableText col px-1 fs-6">
                      {d.order_name ? d.order_name : "N/A"}
                    </div>
                    <div className="tableText col px-1 fs-6">
                      {d.vault.vault_id ? d.vault.vault_id : "N/A"}
                    </div>
                    <div className="tableText col px-1 fs-6">
                      {" "}
                      {d.vault.vault_name ? d.vault.vault_name : "N/A"}
                    </div>
                    <div className="tableText col px-1 fs-6">
                      {userDetails.wallet_address ? (
                        <span className="d-flex gap-2 ">
                          {getShortName(userDetails.wallet_address, false)}{" "}
                          <LuCopy
                            className="cursor-pointer"
                            onClick={() => copyText(userDetails.wallet_address)}
                          />
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </div>
                    <div className="tableText col px-1 fs-6">
                      {d?.txn_link ? (
                        <a
                          className="d-flex gap-2"
                          href={d.txn_link}
                          target="_blank">
                          {getShortName(d.txn_hash, false)}
                          <FiExternalLink />
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };
  const MobileOrderCard = (idx = 1) => {
    return (
      <div
        key={`order-card-+${idx}`}
        className={
          idx % 2
            ? "row orderCardDark py-3 px-2 mb-4"
            : "row orderCardLight py-3 px-2 mb-4"
        }>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start">
            <div className="col-md-12">Transaction Hash</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            {/* <div className="col-md-12"> {getDateFormat(d.date_order)}</div> */}
          </div>
        </div>
        <hr className="px-auto mt-1 text-white" />
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">From</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            {/* <div className="col-md-12">{d.qty}</div> */}
          </div>
        </div>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">To</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            <div className="col-md-12">
              {/* {d.gold_weight ? d.gold_weight.toFixed(GoldDecimal) : "N/A"} */}
            </div>
          </div>
        </div>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">Value</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            {/* <div className="col-md-12">{d.amount}</div> */}
          </div>
        </div>
        <div className="row mt-2 py-2">
          <div className="col-md-5 col-5 tableHeading text-start ">
            <div className="col-md-12">Timestamp</div>
          </div>
          <div className="col-md-7 col-7 tableText text-start">
            {/* <div className="col-md-12">{d.platform_fee}</div> */}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {showModal && (
        <Modal
          size="xl"
          centered
          show={showModal}
          onHide={() => setShowModal(false)}
          className={Styles.modalContainer}>
          <Modal.Body className={Styles.modalBody}>
            <Row className={Styles.header1}>Transaction Detail</Row>
            <Row className="d-none d-md-block d-xl-block mb-4">
              <DesktopOrderTable />
            </Row>
            <Row className="d-block d-xl-none	 d-md-none mb-4">
              {/* <MobileOrderCard /> */}
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default TransactionModalComponent;
