import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";
import { copyText } from '../../utils/common'
import { MdContentCopy } from "react-icons/md";


interface TooltipProps extends ITooltip {
    allowCopy?: boolean
}

const Tooltip = (props: TooltipProps) => {
    const { allowCopy, clickable, ...rest } = props;

    const handleCopy = (content?: string) => {
        if (content)
            copyText(content);
    };

    return (
        <ReactTooltip render={(render) => {
            return (
                <span className="d-flex">
                    {render.content ?? render.activeAnchor?.innerText} {allowCopy && <MdContentCopy size={20} className='cursor-pointer mx-1' onClick={() => handleCopy(render.content ?? render.activeAnchor?.innerText)} />}
                </span>
            )
        }} clickable={allowCopy ?? clickable} {...rest} />
    );
};

export type { TooltipProps };
export { Tooltip };
