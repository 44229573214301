//@ts-nocheck
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ButtonStyles from "../../../Common/Button.module.css";
import Styles from "./confirmModalComponent.module.css";
import OtpInput from "react-otp-input";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateEmail, verifyOTPUpdate } from "../../../../service/api";
import notify from "../../../../utils/notify";

const ConfirmModalComponent = ({
  show,
  onHide,
  handleConfirm,
  email,
  sendOTP,
}: any) => {
  const [code, setCode] = useState("");
  const handleChange = (code: React.SetStateAction<string>) => setCode(code);
  // const sendOTP = async () => {
  //   const res = await updateEmail({ email });
  //   if (res.status) {
  //     notify.success(res.message);
  //   } else {
  //     onHide();
  //   }
  // };
  const verifyOTP = async () => {
    const res = await verifyOTPUpdate({ email, otp: code });
    if (res.status) {
      notify.success(res.message);
      handleConfirm();
      onHide();
    } else {
      notify.error(res.message);
    }
  };
  // useEffect(() => {
  //   if (email) {
  //     sendOTP();
  //   }
  // }, [email]);

  return (
    <Modal centered={true} size="lg" show={show} onHide={onHide}>
      <Modal.Body className="p-5">
        <p className={Styles.header}>Email Update</p>
        <p className={Styles.body}>
          Opt has been sent to your new email address
        </p>
        <OtpInput
          value={code}
          onChange={handleChange}
          numInputs={4}
          renderSeparator={<span style={{ width: "8px" }}></span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={true}
          inputStyle={Styles.InputComponent}
          skipDefaultStyles={true}
          inputType="number"
          containerStyle={Styles.OTPContainer}
        />
        <Row>
          <Col
            md={{ span: 3, offset: 6 }}
            className="justify-content-end d-flex">
            <button className={Styles.resendButton} onClick={sendOTP}>
              Resend
            </button>
          </Col>
        </Row>
        <div className="d-flex justify-content-center gap-4 mt-4">
          <button
            type="submit"
            onClick={onHide}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeWhiteBackground}`}>
            Close
          </button>
          <button
            type="submit"
            onClick={() => {
              verifyOTP();
            }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground}`}>
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmModalComponent;
