import React, { useState } from "react";
import Styles from "./privacyPolicy.module.css";
import { Row, Col } from "react-bootstrap";
import BannerImage from "../../Assets/Images/page404-banner.png";
const PrivatePolicyComponent = () => {
  return (
    <>
      <div>
        <Row className={Styles.infoContainer}>
          <div className={Styles.header1}>
            <div>Privacy Policy. </div>
            <div className={Styles.diffColor}>Savala.</div>
          </div>
          <div className={Styles.subheader}>1. Lorem ipsum</div>
          <div className={Styles.body}>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores et quas molestias excepturi sint occaecati cupiditate non
            provident, similique sunt in culpa qui officia deserunt mollitia
            animi, id est laborum et dolorum fuga.
            <br></br>
            Et harum quidem rerum facilis est et expedita distinctio. Nam libero
            tempore, cum soluta nobis est eligendi optio cumque nihil impedit
            quo minus id quod maxime placeat facere possimus, omnis voluptas
            assumenda est, omnis dolor repellendus.
            <br></br>
            Temporibus autem quibusdam et aut officiis debitis aut rerum
            necessitatibus saepe evenie. Temporibus autem quibusdam et aut
            officiis debitis aut rerum necessitatibus saepe evenie.
            <br></br>
            Temporibus autem quibusdam et aut officiis debitis aut rerum
            necessitatibus saepe eveniet ut et voluptates repudiandae sint et
            molestiae non recusandae. Itaque earum rerum hi.
          </div>
        </Row>
      </div>
    </>
  );
};
export default PrivatePolicyComponent;
