import React from "react";
import SearchOrdersComponent from "../Components/SearchOrdersComponent";
import UserLayout from "../Layouts/UserLayout/UserLayout";
const SearchOrders = ({ role }) => {
  return (
    <UserLayout role={role} showPaddding={false} title="Search Order">
      <SearchOrdersComponent />
    </UserLayout>
  );
};

export default SearchOrders;
