/* eslint-disable default-param-last */
import { USER_TYPES } from "../redux-constant-type/index";

const initialState = {
  username: "",
  walletAddress: null,
  isMetamaskInstalled: false,
  isWalletConnected: false,
  isLoggedIn: false,
  kycStatus: false,
  userDetails: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_TYPES.SET_USERNAME:
      return {
        ...state,
        username: action.payload.username,
      };
    case USER_TYPES.SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.payload.walletAddress,
      };
    case USER_TYPES.SET_METAMASK_INSTALLED_STATUS:
      return {
        ...state,
        isMetamaskInstalled: action.payload.status,
      };
    case USER_TYPES.SET_IS_WALLET_CONNECTED:
      return {
        ...state,
        isWalletConnected: action.payload.status,
      };
    case USER_TYPES.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.status,
      };
    case USER_TYPES.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.payload.userDetails },
      };
    case USER_TYPES.SET_KYC_REASON:
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.payload },
      };
    case USER_TYPES.SET_USER_LOGOUT:
      return {
        ...state,
        username: "",
        walletAddress: null,
        isMetamaskInstalled: false,
        isWalletConnected: false,
        isLoggedIn: false,
        userDetails: {},
      };
    case USER_TYPES.SET_KYC_STATUS:
      return {
        ...state,
        kycStatus: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
