import GetInTouch from "./GetInTouch";
import { Link } from "react-router-dom";
import goldefiLogo from "../../Assets/Images/goldefi/goldefi-logo.svg";
import Styles from "./Footer.module.css";
import { Col, Container, Row } from "react-bootstrap";
import ButtonStyle from "../../Components/Common/Button.module.css";
import LinkedIn from "../../Assets/Images/linkedin.svg";
import Twitter from "../../Assets/Images/twitter.svg";
import Facebook from "../../Assets/Images/facebook.svg";
import Youtube from "../../Assets/Images/youtube.svg";
const Footer = () => {
  return (
    <div className={Styles.DesktopFooterContainer}>
      <Container className={Styles.topContainer}>
        <Row>
          <Col className={Styles.links}>
            <img src={goldefiLogo} alt="GOLDEFI Logo" />
            <div>
              Company info lorem ipsum dolor sit amet consectetur. Facilisi
              habitant lectus senectus vel tristique pellentesque.
            </div>
            <div>Address: lorem ipsum</div>
            <div>Telephone: lorem ipsum</div>
          </Col>
          <Col className={Styles.links}>
            <div className={Styles.header}>Services</div>
            <div>
              <Link to="/">Our Service</Link>
            </div>
            <div>
              <Link to="/">Gold as token</Link>
            </div>
            <div>
              <Link to="/">Tokenize physical gold</Link>
            </div>
            <div>
              <Link to="/">Extraction of gold</Link>
            </div>
          </Col>
          <Col className={Styles.links}>
            <div className={Styles.header}>How it works</div>

            <div>
              <Link to="/">What is tokenized gold</Link>
            </div>
            <div>
              <Link to="/">Physical gold trading</Link>
            </div>
            <div>
              <Link to="/">What is ethical mining</Link>
            </div>
            <div>
              <Link to="/">Decentralised Finance</Link>
            </div>
            <div>
              <Link to="/">Local partnership</Link>
            </div>
          </Col>
          <Col className={Styles.links}>
            <button
              className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareGoldBorder} w-50`}>
              Contact
            </button>
            <button
              className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeSquareOrangeBackground} w-70 mb-4`}>
              Become a partner
            </button>
            <div className={Styles.header}>Follow us</div>
            <div className={Styles.imageContainer}>
              <div>
                <img src={LinkedIn} alt="linkedin" />
              </div>
              <div>
                <img src={Twitter} alt="linkedin" />
              </div>
              <div>
                <img src={Facebook} alt="linkedin" />
              </div>
              <div>
                <img src={Youtube} alt="linkedin" />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={3} className={Styles.links}>
            <div>
              <Link to="/"> Privacy Policy & Cookie Policy</Link>
            </div>
          </Col>
          <Col lg={3} className={Styles.links}>
            <div>
              <Link to="/">Terms & Conditions</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Footer;
