import React from "react";
import PasswordChangedSuccessComponent from "../Components/PasswordChangedSuccessComponent";

const PasswordChangedSuccess = () => {
  return (
    <>
      {/* <Layout title="Password Changed Success"> */}
        <PasswordChangedSuccessComponent />
      {/* </Layout> */}
    </>
  );
};

export default PasswordChangedSuccess;
