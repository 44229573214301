import React from "react";
import Layout from "../Layouts/Layout";
import SubmitOtpComponent from "../Components/SubmitOtpComponent";

const SubmitOtp = () => {
  return (
    <>
      <Layout title="Verify Otp">
        <SubmitOtpComponent />
      </Layout>
    </>
  );
};

export default SubmitOtp;
