import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import ViewPaymentsComponent from "../Components/ViewPayments";

const DistributerPayment = ({ role }) => {
  return (
    <UserLayout role={role} title="Payment Recipt" showPaddding={false}>
      <ViewPaymentsComponent />
    </UserLayout>
  );
};

export default DistributerPayment;
