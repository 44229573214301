//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { bankDetailAPI, bankTransactionAPI } from "../../../service/api";
import ButtonStyles from "../../Common/Button.module.css";
import URLS from "../../../utils/api-end-points";
import Styles from "./BankTransferModalComponent.module.css";
import Copy from "../../../Assets/Images/copy.svg";
import { copyText } from "../../../utils/common";
import notify from "../../../utils/notify";
import InputField from "../../Common/InputField";
import { RiFileCopyLine } from "react-icons/ri";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { IoDownloadOutline } from "react-icons/io5";
import { jsPDF } from "jspdf";
import LoaderComponent from "../../Common/LoaderComponent";
interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  tokenCount: number;
  setTokenCount: (value: number) => void;
  orderId: string;
  orderRef: string;
}
interface BankDetailData {
  bank_name: string;
  ifsc: string;
  email: string;
  phone: string;
  street: string;
  street2: string;
  zip: string;
  state: string;
  country: string;
}

interface BankDetailResponse {
  status: boolean;
  message: string;
  data: BankDetailData;
}
const BankTransferModalComponent = ({
  showModal,
  setShowModal,
  tokenCount,
  setTokenCount,
  orderId,
  orderRef,
}: Props) => {
  const [data, setData] = useState();
  const [transactionId, setTransactionId] = useState("");
  const [bankAddress, setBankAddress] = useState();
  const [loader, setLoader] = useState(false);
  const fetchData = async () => {
    const res: BankDetailResponse = await bankDetailAPI();
    if (res.status) {
      setData(res.data);
      const address = `
      ${res.data.street ? res.data.street : ""}${res.data.street ? ", " : ""} ${
        res.data.street2 ? res.data.street2 : ""
      }${res.data.street2 ? ", " : ""}  ${
        res.data.state ? res.data.state : ""
      }${res.data.state ? ", " : ""}${
        res.data.country ? res.data.country : ""
      }${res.data.country ? ", " : ""}${res.data.zip ? res.data.zip : ""}
      
    
      
      
    `;

      const formattedAddress = address
        .replace(/, ,/g, ",")
        .replace(/, $/, "")
        .trim();
      setBankAddress(formattedAddress);
    } else {
      notify.error(res.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleCopy = (val, text) => {
    copyText(val);
    notify.success(`${text} copied successfully`);
  };
  const handleTransactionID = async () => {
    // if (!transactionId.trim()) {
    //   notify.error("Please Enter Transaction ID!");
    //   return;
    // }
    const body = {
      // transaction_id: "",
      order_id: orderId,
      bank_id: data.id,
    };
    setData();
    setLoader(true);
    const res = await bankTransactionAPI(body);
    setLoader(false);
    if (res.status) {
      notify.success(res.message);
      setShowModal(false);
      setTokenCount(0);
    } else {
      notify.error("Invalid Transaction Id");
    }
  };
  const handleDownload = () => {
    // The updated text content of the file
    const textContent1 = `Send the exact ${tokenCount.toFixed(
      2
    )} to our Bank Account.
Bank Name : ${data.bank_name}
Bank Email : ${data.email}
Bank SWIFT : ${data.swift}
Bank Phone : ${data.phone}
Bank Account : ${data.account_number}
Bank Address : ${bankAddress}`;
    const textContent2 = `When making the payment, please include the following code in the 
"Reason for Payment" or "Payment Recipient Message" field : ${orderRef}.`;
    const doc = new jsPDF();
    //Header-1
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);
    doc.text("Step 1 -", 10, 10);
    //Body-1
    doc.setFont("times", "normal");
    doc.setFontSize(15);
    doc.text(textContent1, 20, 20);
    //Header-2
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);
    doc.text("Step 2 -", 10, 70);
    //Body-2
    doc.setFont("times", "normal");
    doc.setFontSize(15);
    doc.text(textContent2, 20, 80);

    doc.save("bank_details.pdf");
  };
  console.log("loader", loader);
  return (
    <>
      {data && (
        <Modal
          size="xl"
          centered
          show={showModal}
          onHide={() => setShowModal(false)}>
          <Modal.Body className={Styles.modalBody}>
            <div className={Styles.header1}>Bank Transfer</div>

            <Row className={Styles.step}>
              <div className="d-flex justify-content-between">
                <div>Step 1</div>{" "}
                <button
                  className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-auto ${Styles.download}`}
                  onClick={handleDownload}>
                  <IoDownloadOutline />
                  Download Bank Details
                </button>
              </div>
            </Row>
            <Row>
              <Col>
                Send the exact{" "}
                <span className={Styles.totalAmount}>
                  {tokenCount.toFixed(2)}
                </span>{" "}
                to our Bank Account.
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className={Styles.colContainer} md={6}>
                <div className={Styles.bankLabel}>Bank Name :</div>
                <span className={Styles.bankDetail}>{data.bank_name} </span>
                <RiFileCopyLine
                  size={20}
                  className={Styles.copyImg}
                  onClick={() => handleCopy(data.bank_name, "Bank Name")}
                />
              </Col>
              <Col className={Styles.colContainer} md={6}>
                <div className={Styles.bankLabel}>Bank Email :</div>
                <span className={Styles.bankDetail}>{data.email} </span>
                <RiFileCopyLine
                  size={20}
                  className={Styles.copyImg}
                  onClick={() => handleCopy(data.email, "Bank Email")}
                />
              </Col>
              <Col className={Styles.colContainer} md={6}>
                <div className={Styles.bankLabel}>Bank SWIFT :</div>
                <span className={Styles.bankDetail}>{data.swift} </span>
                <RiFileCopyLine
                  size={20}
                  className={Styles.copyImg}
                  onClick={() => handleCopy(data.swift, "Bank IFSC")}
                />
              </Col>
              <Col className={Styles.colContainer} md={6}>
                <div className={Styles.bankLabel}>Bank Phone :</div>
                <span className={Styles.bankDetail}>{data.phone} </span>
                <RiFileCopyLine
                  size={20}
                  className={Styles.copyImg}
                  onClick={() => handleCopy(data.phone, "Bank Phone Number")}
                />
              </Col>
              <Col className={Styles.colContainer} md={6}>
                <div className={Styles.bankLabel}>Bank Account :</div>
                <span className={Styles.bankDetail}>
                  {data.account_number}{" "}
                </span>
                <RiFileCopyLine
                  size={20}
                  className={Styles.copyImg}
                  onClick={() =>
                    handleCopy(data.account_number, "Bank Account")
                  }
                />
              </Col>
              <Col className={Styles.colContainer} md={6}>
                <div className={Styles.bankLabel}>Bank Address :</div>
                <span className={Styles.bankDetail}>{bankAddress} </span>
                <RiFileCopyLine
                  size={20}
                  className={Styles.copyImg}
                  onClick={() =>
                    handleCopy(data.account_number, "Bank Account")
                  }
                />
              </Col>
            </Row>
            <Row className={Styles.step}>
              <div>Step 2</div>
            </Row>
            <Row>
              <Col md={12} className="d-flex gap-1">
                When making the payment, please include the following code in
                the "Reason for Payment" or "Payment Recipient Message" field :
                <div className={Styles.bankDetail}>
                  {orderRef}{" "}
                  <RiFileCopyLine
                    size={20}
                    className={Styles.copyImg}
                    onClick={() => handleCopy(orderRef, "Order Reference")}
                  />
                </div>{" "}
                <br />
              </Col>
              <Col>
                <Row className="text-start">
                  <div className={Styles.warning}>
                    <HiOutlineInformationCircle />
                    Please click on the Confirm Transaction only after
                    completing the the Transaction
                  </div>
                </Row>
              </Col>
            </Row>
            <Row className={`${Styles.step} d-none`}>
              <div>Step 3</div>
            </Row>
            <Row className="d-none">
              <Col md={12}>
                After Sending the Amount to this bank account. Provide us with
                the <span className={Styles.totalAmount}>Transaction Id</span>{" "}
              </Col>
              <br />
              <br />
              <Col md={6}>
                <InputField
                  value={transactionId}
                  type="text"
                  height="50px"
                  placeholder="Enter Transaction ID"
                  onChange={(e) => {
                    const re = /^[a-zA-Z0-9\s]*$/;
                    if (re.test(e.target.value)) {
                      setTransactionId(e.target.value);
                    }
                  }}
                  maxLength={50}
                />
              </Col>
            </Row>
            <Row className={Styles.buttonContainer}>
              <Col lg={2} md={4} sm={6} xs={6}>
                <button
                  onClick={() => setShowModal(false)}
                  className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeBlue} w-100`}>
                  Cancel
                </button>
              </Col>
              <Col lg={4} md={4} sm={6} xs={6}>
                <button
                  onClick={handleTransactionID}
                  className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}>
                  Confirm Transaction
                </button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
      {loader && (
        <LoaderComponent message={"Your transaction is being processed."} />
      )}
    </>
  );
};

export default BankTransferModalComponent;
