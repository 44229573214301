import React from "react";
import Styles from "./UserHeader.module.css";
const CommonHeaderIcon = ({
  path,
  name,
  iconPath,
  selectedIcon,
  icon,
  hover,
}: {
  path: string;
  name: string;
  iconPath: string;
  selectedIcon: string;
  icon: string;
  hover: any;
}) => {
  return (
    <>
      {path == iconPath || hover == iconPath ? (
        <img src={selectedIcon} alt="selectIcon" />
      ) : (
        <img src={icon} alt="Icon" />
      )}
      {path == iconPath ? (
        <span className={Styles.selectedIcon}>{name}</span>
      ) : (
        <span className={Styles.icon}>{name}</span>
      )}
    </>
  );
};

export default CommonHeaderIcon;
