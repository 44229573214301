import React from "react";
import RedeemTokenListComponent from "../Components/RedeemTokenListComponent";
import UserLayout from "../Layouts/UserLayout/UserLayout";

const RedeemToken = () => {
  return (
    <UserLayout title="Redeem Token" showPaddding={false}>
      <RedeemTokenListComponent />
    </UserLayout>
  );
};

export default RedeemToken;
