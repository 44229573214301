import React from "react";
import { useSelector } from "react-redux";
import Styles from "./greeting.module.css";
import { getShortName } from "../../../utils/common";

const GreetingComponent = () => {
  const { userDetails } = useSelector((state: any) => state.userReducer);
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  return (
    <div className={Styles.greetingContainer}>
      <div className="d-flex gap-2">
        <div className={Styles.welcomeText}>Welcome</div>
        <div className={Styles.nameText}>
          {" "}
          {userDetails.name &&
            (getShortName(userDetails.name, screenSize > 426 ? true : false) ||
              "Gabriel")}
        </div>
      </div>
    </div>
  );
};
export default GreetingComponent;
