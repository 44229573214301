
/* eslint-disable default-param-last */

import { PAYMENT_TYPES } from "../redux-constant-type";

interface PaymentType {
    amount: number,
    transaction: string,
    time_stamp: string,
    digital_sign: string,
    payment_type: string,
    state: string,
}
interface state {
    paymentList: PaymentType[]
}
const initialState:state = {
    paymentList: [],
};

const paymentReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case PAYMENT_TYPES.SET_PAYMENT_LIST:            
            return {
                ...state,
                paymentList: [...action.payload.paymentList],
            };
        default:
            return state;
    }
};

export default paymentReducer;
